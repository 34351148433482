enum ActionType {
    //damage-statistics
    SET_DEALERS = 'SET_DEALERS',
    SET_DAMAGE_CENTERS_LOADING = 'SET_DAMAGE_CENTERS_LOADING',
    SET_DAMAGE_CENTERS_ERROR = 'SET_DAMAGE_CENTERS_ERROR',

    RESET_DAMAGE_DASHBOARD = 'RESET_DAMAGE_DASHBOARD',
}

export default ActionType;
