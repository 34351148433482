import { Button, Message, MessageThread } from '@carcare/components';
import { arrow_downward } from '@carcare/components/dist/icons';
import { DOWNLOAD_VALUATION_ATTACHMENT } from 'src/http/endpoints';
import composeUrl from 'src/http/utils/composeUrl';
import {
    Message as IMessage,
    Thread,
} from '../../../../http/message-center/types/generated/MessageCenterTypes';
import { sortByTime } from '../../../../utils/time/timeUtils';
import { SessionStorage } from '../../../../utils/enums/SessionStorage';
import { getCurrentEnv } from '../../../../env';
import { saveAs } from 'file-saver';

interface MessagesProps {
    readonly thread?: Thread;
    readonly messages: IMessage[];
}

function isMessageSeen(message: IMessage, seenByCustomer: boolean | undefined) {
    if (message.source === 'BILHOLD') {
        /*
            Workaround, as seenByCustomer from Bilhold now is immediately
            set as "seenByCustomer".
            TODO: seenByCustomer should not be set as seen, if customer has not seen it.
        */
        return 'sent';
    }

    return seenByCustomer ? 'seenByCustomer' : 'sent';
}

const Messages = ({ thread, messages }: MessagesProps) => {
    const isDamageAppraiser = thread?.category === 'DAMAGE_APPRAISER';
    const sortedMessages = [...messages].sort((a, b) =>
        sortByTime(a.createdAt, b.createdAt)
    );
    const filteredMessages = isDamageAppraiser
        ? sortedMessages.slice(1)
        : sortedMessages;

    const lastMessage = filteredMessages[filteredMessages.length - 1];
    const seenByCustomer = thread
        ? thread.participants?.find((p) => !p.internal)?.seen
        : false;
    const getFileName = (path: string): string => path.split('/').pop() ?? '';

    const onButtonPress = () => {
        window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="ba-messages">
            <div className="ba-messages__scroll-button">
                <Button
                    textKey="scrollToNewestMessage"
                    onClick={onButtonPress}
                    icon={arrow_downward}
                />
            </div>
            <MessageThread>
                {filteredMessages.map((message) => (
                    <Message
                        messageText={message.content}
                        senderName={message.sender?.name}
                        isSentByMe={!!message.sender?.internal}
                        sentTime={message.createdAt ?? ''}
                        readStatus={isMessageSeen(message, seenByCustomer)}
                        isShowingReadStatus={
                            message.messageId === lastMessage?.messageId &&
                            message.sender.internal
                        }
                        attachments={message.attachments?.map((a) => {
                            return {
                                filePath: composeUrl(
                                    DOWNLOAD_VALUATION_ATTACHMENT,
                                    getFileName(a.filePath)
                                ),
                                mimeType: 'application/pdf',
                                fileName: a.messageId,
                            };
                        })}
                        downloadAttachment={(a) => {
                            void openPdfWithAuth(
                                DOWNLOAD_VALUATION_ATTACHMENT,
                                getFileName(a.filePath)
                            );
                        }}
                        key={message.messageId}
                    />
                ))}
            </MessageThread>
        </div>
    );
};

const fetchBlob = async (url: string) => {
    const headers = {
        Authorization: `Bearer ${sessionStorage.getItem(
            SessionStorage.ID_TOKEN
        )!}`,
    };
    const res = await fetch(url, { headers });
    console.log(res);
    const blob = await res.blob();
    return blob;
};
function isIOSSafari() {
    const ua = window.navigator.userAgent;
    const iOS = /iP(ad|hone)/i.test(ua);
    const webkit = /WebKit/i.test(ua);
    const isChromeFirefoxOrEdge = /(Cr|Fx|Edg)iOS/i.test(ua);
    return iOS && webkit && !isChromeFirefoxOrEdge;
}

const openPdfWithAuth = async (filePath: string, fileName: string) => {
    const blobdata = await fetchBlob(composeUrl(filePath, fileName));

    if (isIOSSafari()) {
        // window.open doesn't work on iOS Safari, but for this particular browser
        // file-saver will open the file in the current window
        saveAs(blobdata, fileName);
    } else {
        // On other browsers file-saver will download the file directly instead of opening it in the browser.
        // Therefore we don't use file-saver when we're not on iOS Safari
        const fileURL = URL.createObjectURL(blobdata);
        window.open(fileURL);
    }
};
export default Messages;
