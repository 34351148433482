import { NewRequestTypes } from './types';
import ActionType from './ActionType';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

export interface NewRequestState {
    newDamageValuations: DamageAppraiserValuation[];
    newDamageValuationsIsLoading: boolean;
    newDamageValuationsHasError: boolean;
    newDamageValuationsHasFinished: boolean;
}

export const initialState: NewRequestState = {
    newDamageValuations: [],
    newDamageValuationsIsLoading: false,
    newDamageValuationsHasError: false,
    newDamageValuationsHasFinished: false,
};

const newRequestReducer = (
    state: NewRequestState = initialState,
    action: NewRequestTypes
): NewRequestState => {
    switch (action.type) {
        case ActionType.SET_NEW_DAMAGE_VALUATIONS: {
            return {
                ...state,
                newDamageValuations:
                    state.newDamageValuations.length === 0
                        ? action.payload
                        : state.newDamageValuations.concat(action.payload),
            };
        }

        case ActionType.SET_NEW_DAMAGE_VALUATIONS_LOADING: {
            return {
                ...state,
                newDamageValuationsIsLoading: action.payload,
            };
        }

        case ActionType.SET_NEW_DAMAGE_VALUATIONS_ERROR: {
            return {
                ...state,
                newDamageValuationsHasError: action.payload,
            };
        }

        case ActionType.SET_NEW_DAMAGE_VALUATIONS_FINISHED: {
            return {
                ...state,
                newDamageValuationsHasFinished: action.payload,
            };
        }

        default:
            return state;
    }
};

export default newRequestReducer;
