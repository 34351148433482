import React from 'react';
import cn from 'classnames';
import { Icon, Text, Input } from '@carcare/components';
import { document_pdf } from '@carcare/components/dist/icons/info';
import UploadButton from '../../../../components/upload-button/UploadButton';
import postValuationAttachment from '../../../../http/carcareApi/postValuationAttachment';
import ValuationPriceInput from '../valuation-price-input/ValuationPriceInput';
import { useDispatch } from '../../../../store/useStore';
import { Attachment as IAttachment } from '../../../../http/message-center/types/generated/MessageCenterTypes';
import composeUrl from 'src/http/utils/composeUrl';
import { ATTACHMENT_FILEPATH } from 'src/http/endpoints';

interface UploadValuationProps {
    readonly relatedId: number;
    readonly id: number;
    readonly userId: number;
    readonly setAttachment: (value: IAttachment) => void;
    readonly attachments: IAttachment[];
    readonly totalPrice: string;
    readonly handleSetPrices: (
        event: React.ChangeEvent<HTMLInputElement>,
        attachment: string
    ) => void;
    readonly prices: Record<string, string>;
}

const UploadValuation = ({
    relatedId,
    id,
    userId,
    setAttachment,
    attachments,
    totalPrice,
    handleSetPrices,
    prices,
}: UploadValuationProps) => {
    const dispatch = useDispatch();
    const getFilePath = (fileName: string) =>
        composeUrl(ATTACHMENT_FILEPATH, fileName);
    return (
        <div className="ba-upload-valuation">
            {attachments?.length > 0 && (
                <div
                    className={cn('ba-upload-valuation__attachments', {
                        'ba-upload-valuation__attachments--single':
                            attachments?.length === 1,
                    })}
                >
                    {attachments.map((attachment) => (
                        <div
                            className="ba-upload-valuation__attachments__wrapper"
                            key={attachment.filePath}
                        >
                            <div className="ba-upload-valuation__attachments__wrapper__item">
                                <Icon file={document_pdf} />
                                <Text textKey="valuation" />
                            </div>
                            <div className="ba-upload-valuation__attachments__wrapper__input">
                                <ValuationPriceInput
                                    prices={prices}
                                    handleSetPrices={handleSetPrices}
                                    attachment={attachment.filePath}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="ba-upload-valuation__column">
                <UploadButton
                    textKey="addValuation"
                    relatedId={relatedId}
                    uploadMedia={(fileData) =>
                        postValuationAttachment(id, dispatch, {
                            relatedId: relatedId,
                            data: fileData.file,
                            userId,
                        }).then((res) => {
                            if (!res) return;
                            setAttachment({
                                filePath: getFilePath(res.data.fileName),
                                mimeType: res.data.fileType,
                                attachmentId: res.data.fileName,
                            });
                        })
                    }
                />
                {attachments?.length > 0 && (
                    <Input
                        onChange={() => undefined}
                        label={{ textKey: 'totalPriceLabel' }}
                        value={totalPrice}
                        typeInput="number"
                        fixedLabel
                        disabled
                    />
                )}
            </div>
        </div>
    );
};

export default UploadValuation;
