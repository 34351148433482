import React from 'react';
import useStore from '../../store/useStore';
import Header from './Header';
import { setToggleMenu } from '../../store/view/actions';
import { useLocation } from 'react-router-dom';
import Search from '../search/Search';
import {
    PATH_COMPLETED_REQUESTS,
    PATH_DAMAGE,
    PATH_NEW_REQUESTS,
    PATH_ONGOING_REQUESTS,
} from '../../router/routes';
import isIE11 from '../../utils/browser/isIE11';
import IEHeader from './components/ie-header/IEHeader';
import { useIsAuthenticated } from '@azure/msal-react';
import featureToggle from 'src/featureToggle';
import PlannedMaintenance from 'src/router/PlannedMaintenance';

const HeaderContainer = () => {
    const [store, dispatch] = useStore();
    const isAuthenticated = useIsAuthenticated();
    const currentPage = useLocation();
    const {
        view: { isMenuVisible },
        account: { user },
    } = store;
    const showDamageSearch =
        currentPage?.pathname === PATH_DAMAGE ||
        currentPage?.pathname === PATH_NEW_REQUESTS ||
        currentPage?.pathname === PATH_ONGOING_REQUESTS ||
        currentPage?.pathname === PATH_COMPLETED_REQUESTS;

    return (
        <>
            <Header
                currentPage={currentPage?.pathname}
                isAuthenticated={isAuthenticated}
                name={user?.name}
                toggleMenu={() => dispatch(setToggleMenu(!isMenuVisible))}
            />
            {isIE11() && <IEHeader />}
            {featureToggle.plannedMaintanence && <PlannedMaintenance />}
            {!featureToggle.downForMaintenance &&
                isAuthenticated &&
                showDamageSearch && <Search />}
        </>
    );
};

export default HeaderContainer;
