import DamageAppraiserValuation from '../types/DamageAppraiserValuation';

const licensePlateFallback = (
    damageValuation: DamageAppraiserValuation
): string => {
    return (
        damageValuation?.car?.licensePlate ??
        damageValuation?.damageAppraiser?.licensePlate
    );
};

export default licensePlateFallback;
