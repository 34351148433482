import React from 'react';
import { Icon, IconText, Text } from '@carcare/components';
import cn from 'classnames';
import {
    bilholdClasses,
    ComponentState,
    ComponentType,
} from '@carcare/components/dist/styles';
import { Size } from '@carcare/components/dist/types';

interface StatisticsCardInterface {
    readonly rightText?: string;
    readonly icon: React.FC;
    readonly state: ComponentState;
    readonly iconText?: string;
    readonly iconState?: ComponentState;
    readonly cardContentText?: string;
}
const StatisticsCard = ({
    rightText,
    icon,
    iconState,
    state,
    iconText,
    cardContentText,
}: StatisticsCardInterface) => {
    return (
        <div
            className={cn(
                'ba-statistics-card',
                bilholdClasses(ComponentType.CONTAINER, state)
            )}
        >
            <div className="ba-statistics-card__icon-text__row">
                <IconText
                    icon={<Icon file={icon} state={iconState} />}
                    text={<Text textKey={iconText} size={Size.LARGE} />}
                />
                <Text
                    className="ba-statistics-card__icon-text__right"
                    textContent={rightText}
                />
            </div>

            <div className="ba-statistics-card__content-text">
                <Text size={Size.XL} textKey={cardContentText} />
            </div>
        </div>
    );
};

export default StatisticsCard;
