import { Dispatch } from 'redux';
import { useSelector, useDispatch as useReduxDispatch } from 'react-redux';
import { ApplicationState } from './reducers';

export default function useStore(): [ApplicationState, Dispatch] {
    const state = useSelector((store) => store);
    const dispatch = useDispatch();
    return [state, dispatch];
}

export const useDispatch = useReduxDispatch;
