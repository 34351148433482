import React from 'react';
import Menu from './Menu';
import useStore from '../../store/useStore';
import { useIsAuthenticated } from '@azure/msal-react';

const MenuContainer = () => {
    const [store] = useStore();
    const isAuthenticated = useIsAuthenticated();
    const {
        view: { isMenuVisible },
    } = store;

    return (
        <Menu isAuthenticated={isAuthenticated} isMenuVisible={isMenuVisible} />
    );
};

export default MenuContainer;
