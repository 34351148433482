import React, { ReactElement, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Router, Switch } from 'react-router-dom';
import {
    LoadingOverlay,
    Spinner as Loading,
    TranslationProvider,
} from '@carcare/components';
import { history } from './utils/history';
import OnPageChange from './OnPageChange';
import Header from '../components/header';
import Menu from '../components/menu';
import Damage from '../views/damage';
import NewRequests from '../views/new-requests';
import OngoingRequests from '../views/ongoing-requests';
import CompletedRequests from '../views/completed-requests';
import MessageIndex from '../views/messages/messageIndex';
import Request from '../views/request';
import {
    PATH_COMPLETED_REQUESTS,
    PATH_DAMAGE,
    PATH_DAMAGE_STATISTICS,
    PATH_HOME,
    PATH_MESSAGES,
    PATH_NEW_REQUESTS,
    PATH_ONGOING_REQUESTS,
    PATH_REQUEST,
} from './routes';
import useInitializeAdmin from './utils/useInitializeAdmin';
import DamageStatistics from '../views/damage-statistics';
import AuthenticationGuard from '../http/azureAD/utils/AuthenticationGuard';
import NoContent from './NoContent';
import DownForMaintenance from './DownForMaintenance';
import featureToggle from '../featureToggle';
import ViewMessageIndex from 'src/views/view-message/viewMessageIndex';

const App = (): ReactElement => {
    const [translate] = useTranslation();
    const { isInitiated } = useInitializeAdmin();

    if (!isInitiated) return <LoadingOverlay delayMillis={0} />;

    if (featureToggle.downForMaintenance) {
        return (
            <TranslationProvider translate={translate}>
                <Router history={history}>
                    <DownForMaintenance />
                </Router>
            </TranslationProvider>
        );
    }

    return (
        <TranslationProvider translate={translate}>
            <Router history={history}>
                <AuthenticationGuard>
                    <Suspense fallback={<Loading />}>
                        <OnPageChange>
                            <Header />
                            <Menu />
                            <Switch>
                                <Route
                                    exact
                                    path={PATH_HOME}
                                    component={NoContent}
                                />
                                <Route
                                    exact
                                    path={PATH_DAMAGE}
                                    component={Damage}
                                />
                                <Route
                                    path={`${PATH_REQUEST}/:hash`}
                                    component={Request}
                                />
                                <Route
                                    exact
                                    path={PATH_NEW_REQUESTS}
                                    component={NewRequests}
                                />
                                <Route
                                    exact
                                    path={PATH_MESSAGES}
                                    component={MessageIndex}
                                />
                                <Route
                                    path={`${PATH_MESSAGES}/:threadId`}
                                    component={ViewMessageIndex}
                                />
                                <Route
                                    exact
                                    path={PATH_ONGOING_REQUESTS}
                                    component={OngoingRequests}
                                />
                                <Route
                                    exact
                                    path={PATH_COMPLETED_REQUESTS}
                                    component={CompletedRequests}
                                />
                                <Route
                                    exact
                                    path={PATH_DAMAGE_STATISTICS}
                                    component={DamageStatistics}
                                />
                                <Route component={NoContent} />
                            </Switch>
                        </OnPageChange>
                    </Suspense>
                </AuthenticationGuard>
            </Router>
        </TranslationProvider>
    );
};

export default App;
