import { Icon, IconTextLink, Text } from '@carcare/components';
import { ComponentState, FlexDirection } from '@carcare/components/dist/styles';
import { download } from '@carcare/components/dist/icons';
import fetchValuationImages from '../../../../http/carcareApi/fetchValuationImages';
import React from 'react';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import { useTranslation } from 'react-i18next';
import ImageFromLocal from './Image/ImageFromLocal';

interface RequestImagesProps {
    readonly damageValuation: DamageAppraiserValuation;
    readonly hash: string;
}

const RequestImages = ({ damageValuation, hash }: RequestImagesProps) => {
    const [translate] = useTranslation();
    const downloadImagesFolderName = translate('valuationImages');
    const images = damageValuation?.damageAppraiser?.images;

    return (
        <>
            <Text className="b-images__title" textKey="valuationImages" />
            <div className="b-images__container">
                {images.map((imageId) => (
                    <div className="b-images__container-item" key={imageId}>
                        <ImageFromLocal
                            hash={hash}
                            imageId={imageId}
                            damageValuation={damageValuation}
                            imageAltText={downloadImagesFolderName}
                        />
                    </div>
                ))}
            </div>
            <IconTextLink
                className="ba-request-info__damage__download"
                direction={FlexDirection.LEFT}
                text={
                    <Text
                        textContent={`
                                ${translate('download')}
                                ${
                                    damageValuation?.damageAppraiser?.images
                                        .length
                                }
                                ${translate('images')}`}
                        state={ComponentState.INFO}
                    />
                }
                icon={<Icon file={download} state={ComponentState.INFO} />}
                onClick={() =>
                    fetchValuationImages(
                        damageValuation?.id,
                        downloadImagesFolderName
                    )
                }
            />
        </>
    );
};

export default RequestImages;
