import React from 'react';
import { LoadingOverlay } from '@carcare/components';
import useStore from '../../store/useStore';
import DamageValuationState from '../../utils/enums/DamageValuationState';
import RequestShortlist from './components/request-shortlist/RequestShortlist';
import {
    PATH_COMPLETED_REQUESTS,
    PATH_NEW_REQUESTS,
    PATH_ONGOING_REQUESTS,
} from '../../router/routes';
import PageLink from '../../components/page-link/PageLink';
import {
    setNewDamageValuations,
    setNewDamageValuationsError,
    setNewDamageValuationsFinished,
    setNewDamageValuationsLoading,
} from 'src/store/damage/newRequest/actions';
import {
    setOngoingDamageValuations,
    setOngoingDamageValuationsError,
    setOngoingDamageValuationsFinished,
    setOngoingDamageValuationsLoading,
} from 'src/store/damage/ongoingRequests/actions';

const Damage = () => {
    const [store] = useStore();
    const {
        damage: {
            newRequests: {
                newDamageValuations,
                newDamageValuationsIsLoading,
                newDamageValuationsHasFinished,
                newDamageValuationsHasError,
            },
            ongoingRequests: {
                ongoingDamageValuations,
                ongoingDamageValuationsIsLoading,
                ongoingDamageValuationsHasFinished,
                ongoingDamageValuationsHasError,
            },
        },
    } = store;

    if (newDamageValuationsIsLoading || ongoingDamageValuationsIsLoading) {
        return <LoadingOverlay delayMillis={500} />;
    } else
        return (
            <div className="ba-damage">
                <RequestShortlist
                    listTitle="newRequests"
                    damageAppraiserValuations={newDamageValuations}
                    isLoading={newDamageValuationsIsLoading}
                    hasFinished={newDamageValuationsHasFinished}
                    hasError={newDamageValuationsHasError}
                    setIsLoading={setNewDamageValuationsLoading}
                    setHasError={setNewDamageValuationsError}
                    setHasFinished={setNewDamageValuationsFinished}
                    setDamageAppraiserValuations={setNewDamageValuations}
                    state={DamageValuationState.NEW}
                    listPagePath={PATH_NEW_REQUESTS}
                    listPageText="allNewRequests"
                    noResultsTextKey="noNewResults"
                />
                <RequestShortlist
                    listTitle="ongoingRequests"
                    damageAppraiserValuations={ongoingDamageValuations}
                    isLoading={ongoingDamageValuationsIsLoading}
                    hasFinished={ongoingDamageValuationsHasFinished}
                    hasError={ongoingDamageValuationsHasError}
                    setIsLoading={setOngoingDamageValuationsLoading}
                    setHasError={setOngoingDamageValuationsError}
                    setHasFinished={setOngoingDamageValuationsFinished}
                    setDamageAppraiserValuations={setOngoingDamageValuations}
                    state={DamageValuationState.PENDING}
                    listPagePath={PATH_ONGOING_REQUESTS}
                    listPageText="allOngoingRequests"
                    noResultsTextKey="noOngoingResults"
                />
                <PageLink
                    className="ba-damage__link"
                    path={PATH_COMPLETED_REQUESTS}
                    textKey="allCompletedRequests"
                />
            </div>
        );
};

export default Damage;
