import React from 'react';
import {
    setNewDamageValuations,
    setNewDamageValuationsError,
    setNewDamageValuationsFinished,
    setNewDamageValuationsLoading,
} from 'src/store/damage/newRequest/actions';
import DamageListPage from '../../components/damage-list-page/DamageListPage';
import useStore from '../../store/useStore';
import DamageValuationState from '../../utils/enums/DamageValuationState';

const Index = () => {
    const [store] = useStore();
    const {
        damage: {
            newRequests: {
                newDamageValuations,
                newDamageValuationsIsLoading,
                newDamageValuationsHasFinished,
                newDamageValuationsHasError,
            },
        },
    } = store;

    return (
        <DamageListPage
            listTitle="allNewRequests"
            damageAppraiserValuations={newDamageValuations}
            isLoading={newDamageValuationsIsLoading}
            hasFinished={newDamageValuationsHasFinished}
            hasError={newDamageValuationsHasError}
            setIsLoading={setNewDamageValuationsLoading}
            setHasError={setNewDamageValuationsError}
            setHasFinished={setNewDamageValuationsFinished}
            setDamageAppraiserValuations={setNewDamageValuations}
            state={DamageValuationState.NEW}
        />
    );
};

export default Index;
