enum CompletedReason {
    CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
    CUSTOMER_NO_RESPONSE = 'CUSTOMER_NO_RESPONSE',
    CUSTOMER_DECLINED = 'CUSTOMER_DECLINED',
    APPRAISE_AT_DAMAGE_CENTER = 'APPRAISE_AT_DAMAGE_CENTER',
    CONDEMNED = 'CONDEMNED',
    CASH_SETTLEMENT = 'CASH_SETTLEMENT',
    INVALID_APPRAISER = 'INVALID_APPRAISER',
}

export default CompletedReason;
