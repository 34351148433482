import React from 'react';
import { Page, SimpleInfoBox } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';

const PlannedMaintenance = () => {
    return (
        <Page>
            <SimpleInfoBox
                state={ComponentState.INFO}
                text={{
                    textContent:
                        'Det er planlagt vedlikehold av bilhold admin på tirsdag 29. mars. Fra 11.00 til 16.00 vil tjenesten være utilgjengelig.',
                }}
            />
        </Page>
    );
};

export default PlannedMaintenance;
