import React, { useLayoutEffect, useRef } from 'react';
import { SimpleInfoBox } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';

interface RequestErrorProps {
    readonly errorMessage: string;
}

const RequestError = ({ errorMessage }: RequestErrorProps) => {
    const ref = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        ref.current?.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }, []);

    return (
        <div ref={ref}>
            <SimpleInfoBox
                className="ba-request__error"
                state={ComponentState.ERROR}
                text={{ textKey: errorMessage }}
            />
        </div>
    );
};

export default RequestError;
