import React from 'react';
import { Input } from '@carcare/components';

interface ValuationPriceInputProps {
    readonly prices: Record<string, string>;
    readonly handleSetPrices: (
        event: React.ChangeEvent<HTMLInputElement>,
        attachment: string
    ) => void;
    readonly attachment: string;
}

const ValuationPriceInput = ({
    prices,
    handleSetPrices,
    attachment,
}: ValuationPriceInputProps) => {
    return (
        <Input
            onChange={(e) => {
                handleSetPrices(e, attachment);
            }}
            label={{ textKey: 'valuationPriceLabel' }}
            value={prices[attachment] ? prices[attachment].toString() : ''}
            typeInput="number"
            required
        />
    );
};

export default ValuationPriceInput;
