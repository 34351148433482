import { Button, TextArea } from '@carcare/components';

interface NewMessageContentProps {
    readonly setMessage: (value: string) => void;
    readonly message: string;
    readonly isLoading: boolean;
    readonly sendMessage: () => void;
    readonly loggedInUser: { name: string; username: string };
}

const NewMessageContent = ({
    setMessage,
    message,
    sendMessage,
    isLoading,
}: NewMessageContentProps) => {
    const sendButtonIsValid = () => {
        return !!message.trim().length && !isLoading;
    };

    return (
        <div className="ba-new-message-content">
            <div className="ba-new-message-content__bottom">
                <TextArea
                    className="ba-new-message-content__bottom__text-area"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Send a message"
                    showEmptyError={false}
                    showMaxLength={false}
                    maxLength={4000}
                />
                <div className="ba-new-message-content__bottom__buttons">
                    <Button
                        disabled={!sendButtonIsValid()}
                        textKey="sendMessage"
                        onClick={() => sendMessage()}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewMessageContent;
