import React from 'react';
import moment from 'moment';
import { TextArea, Input, Button, ButtonType } from '@carcare/components';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import formatMessageTemplate from '../../utils/formatMessageTemplate';
import messageTemplateData from '../../utils/messageTemplateData';
import {
    DATE_FORMAT,
    INPUT_DATE_FORMAT,
} from '../../../../utils/time/timeFormat';
import UploadValuation from '../upload-valuation/UploadValuation';
import isIE from '../../../../utils/browser/isIE11';
import MessageTemplateType from '../../../../utils/enums/MessageTemplateType';
import { Attachment as IAttachment } from '../../../../http/message-center/types/generated/MessageCenterTypes';

import MessageTemplate from 'src/types/MessageTemplate';

interface NewMessageContentProps {
    readonly damageValuation: DamageAppraiserValuation;
    readonly setMessage: (value: string) => void;
    readonly message: string;
    readonly setDate: (value: string) => void;
    readonly date: string;
    readonly setEstimatedFinished: (value: string) => void;
    readonly estimatedFinished: string;
    readonly messageTemplate: MessageTemplate;
    readonly showEstimatedFinishedInput: boolean;
    readonly showDateInput: boolean;
    readonly setAttachment: (value: IAttachment) => void;
    readonly attachments: IAttachment[];
    readonly totalPrice: string;
    readonly sendMessage: () => void;
    readonly loggedInUser: { name: string; username: string };
    readonly handleSetPrices: (
        event: React.ChangeEvent<HTMLInputElement>,
        attachment: string
    ) => void;
    readonly prices: Record<string, string>;
    readonly resetNewMessage: () => void;
}

const NewMessageContent = ({
    damageValuation,
    setMessage,
    message,
    setDate,
    date,
    messageTemplate,
    estimatedFinished,
    setEstimatedFinished,
    showEstimatedFinishedInput,
    showDateInput,
    setAttachment,
    attachments,
    totalPrice,
    sendMessage,
    loggedInUser,
    handleSetPrices,
    prices,
    resetNewMessage,
}: NewMessageContentProps) => {
    const attachmentWithoutPrice = attachments?.some(
        (a) => !prices[a.filePath] || prices[a.filePath] === ''
    );

    const sendButtonIsValid = () => {
        if (message.trim()) {
            if (messageTemplate?.type === MessageTemplateType.APPRAISER) {
                return (
                    attachments.length > 0 &&
                    !!date &&
                    !!estimatedFinished &&
                    !attachmentWithoutPrice
                );
            }
            if (messageTemplate?.type === MessageTemplateType.TOO_DAMAGED) {
                return !!date;
            } else {
                return true;
            }
        }
        return false;
    };

    return (
        <div className="ba-new-message-content">
            {(showDateInput || showEstimatedFinishedInput) && (
                <div className="ba-new-message-content__inputs">
                    {showDateInput && (
                        <Input
                            onChange={(e) => {
                                setDate(e.target.value);
                                setMessage(
                                    formatMessageTemplate(
                                        messageTemplate?.text,
                                        messageTemplateData(
                                            damageValuation,
                                            loggedInUser,
                                            {
                                                date: moment(
                                                    e.target.value
                                                ).format(DATE_FORMAT),
                                                duration:
                                                    moment(
                                                        estimatedFinished
                                                    ).format(DATE_FORMAT),
                                            }
                                        )
                                    )
                                );
                            }}
                            label={{ textKey: 'repairDate' }}
                            helpText={
                                isIE()
                                    ? { textKey: 'dateFormatHelpText' }
                                    : undefined
                            }
                            value={date}
                            typeInput="date"
                            required
                            min={moment().format(INPUT_DATE_FORMAT)}
                        />
                    )}
                    {showEstimatedFinishedInput && (
                        <Input
                            onChange={(e) => {
                                setEstimatedFinished(e.target.value);
                                setMessage(
                                    formatMessageTemplate(
                                        messageTemplate?.text,
                                        messageTemplateData(
                                            damageValuation,
                                            loggedInUser,
                                            {
                                                date: moment(date).format(
                                                    DATE_FORMAT
                                                ),
                                                duration: moment(
                                                    e.target.value
                                                ).format(DATE_FORMAT),
                                            }
                                        )
                                    )
                                );
                            }}
                            label={{ textKey: 'estimatedFinishedDate' }}
                            helpText={
                                isIE()
                                    ? { textKey: 'dateFormatHelpText' }
                                    : undefined
                            }
                            value={estimatedFinished}
                            typeInput="date"
                            required
                            min={moment().format(INPUT_DATE_FORMAT)}
                        />
                    )}
                </div>
            )}
            {showEstimatedFinishedInput && (
                <UploadValuation
                    id={damageValuation.id}
                    relatedId={damageValuation.damageAppraiserId}
                    userId={damageValuation.customer.userId}
                    setAttachment={setAttachment}
                    attachments={attachments}
                    totalPrice={totalPrice}
                    handleSetPrices={handleSetPrices}
                    prices={prices}
                />
            )}
            <div className="ba-new-message-content__bottom">
                <TextArea
                    className="ba-new-message-content__bottom__text-area"
                    value={message}
                    onChange={(e) =>
                        setMessage(
                            formatMessageTemplate(
                                e.target.value,
                                messageTemplateData(
                                    damageValuation,
                                    loggedInUser,
                                    {
                                        date: moment(date).format(DATE_FORMAT),
                                        duration:
                                            moment(estimatedFinished).format(
                                                DATE_FORMAT
                                            ),
                                    }
                                )
                            )
                        )
                    }
                    showEmptyError={false}
                    showMaxLength={false}
                    maxLength={4000}
                />
                <div className="ba-new-message-content__bottom__buttons">
                    <Button
                        textKey="cancel"
                        buttonType={ButtonType.SECONDARY}
                        onClick={() => resetNewMessage()}
                    />
                    <Button
                        disabled={!sendButtonIsValid()}
                        textKey="sendMessage"
                        onClick={() => sendMessage()}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewMessageContent;
