enum ActionType {
    //request
    SET_DAMAGE_VALUATION = 'SET_DAMAGE_VALUATION',
    SET_DAMAGE_VALUATION_LOADING = 'SET_DAMAGE_VALUATION_LOADING',
    SET_DAMAGE_VALUATION_ERROR = 'SET_DAMAGE_VALUATION_ERROR',

    SET_REQUEST_MESSAGES = 'SET_REQUEST_MESSAGES',
    SET_REQUEST_MESSAGES_LOADING = 'SET_REQUEST_MESSAGES_LOADING',
    SET_REQUEST_MESSAGES_ERROR = 'SET_REQUEST_MESSAGES_ERROR',

    SET_REQUEST_THREAD = 'SET_REQUEST_THREAD',
    SET_REQUEST_THREAD_LOADING = 'SET_REQUEST_THREAD_LOADING',
    SET_REQUEST_THREAD_ERROR = 'SET_REQUEST_THREAD_ERROR',

    SET_MESSAGE_TEMPLATES = 'SET_MESSAGE_TEMPLATES',
    SET_NEW_MESSAGE_LOADING = 'SET_NEW_MESSAGE_LOADING',
    SET_NEW_MESSAGE_ERROR = 'SET_NEW_MESSAGE_ERROR',

    RESET_DAMAGE_DASHBOARD = 'RESET_DAMAGE_DASHBOARD',
}

export default ActionType;
