import React, { useCallback } from 'react';
import {
    Page,
    Spinner,
    Button,
    ButtonType,
    SimpleInfoBox,
} from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import { useDispatch } from '../../store/useStore';
import DamageList from '../damage-list/DamageList';
import { PATH_DAMAGE } from '../../router/routes';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';
import fetchDamageAppraisers from '../../http/carcareApi/fetchDamageAppraisers';
import DamageValuationState from '../../utils/enums/DamageValuationState';
import useFetchDamageValuations from '../../http/carcareApi/utils/useFetchDamageValuations';
import PageLink from '../page-link/PageLink';
import DamageNoResults from '../damage-no-results/DamageNoResults';
import { AnyAction } from 'redux';

interface ListProps {
    readonly listTitle: string;
    readonly damageAppraiserValuations: DamageAppraiserValuation[];
    readonly isLoading: boolean;
    readonly hasFinished: boolean;
    readonly hasError: boolean;
    readonly setIsLoading: (value: boolean) => AnyAction;
    readonly setHasError: (value: boolean) => AnyAction;
    readonly setHasFinished: (value: boolean) => AnyAction;
    readonly setDamageAppraiserValuations: (
        value: DamageAppraiserValuation[]
    ) => AnyAction;
    readonly state: DamageValuationState;
    readonly limit?: string;
}

const DamageListPage = ({
    listTitle,
    damageAppraiserValuations,
    isLoading,
    hasFinished,
    hasError,
    setIsLoading,
    setHasError,
    setHasFinished,
    setDamageAppraiserValuations,
    state,
    limit = '15',
}: ListProps) => {
    const dispatch = useDispatch();

    const loadDamageAppraisers = useCallback(() => {
        fetchDamageAppraisers({
            dispatch,
            setIsLoading,
            setHasError,
            setHasFinished,
            setDamageAppraiserValuations,
            state,
            limit,
            offset: damageAppraiserValuations.length,
        });
    }, [
        dispatch,
        setIsLoading,
        setHasError,
        setHasFinished,
        setDamageAppraiserValuations,
        state,
        limit,
        damageAppraiserValuations,
    ]);

    useFetchDamageValuations(
        damageAppraiserValuations,
        isLoading,
        hasFinished,
        hasError,
        loadDamageAppraisers
    );

    return (
        <Page className="ba-list-page">
            <PageLink
                className="ba-list-page__back"
                path={PATH_DAMAGE}
                textKey="backToDashboard"
                isBack
            />
            <DamageList
                damageAppraiserValuations={damageAppraiserValuations}
                listTitle={listTitle}
            />
            {isLoading && (
                <div className="ba-list-page__spinner">
                    <Spinner delayMillis={0} />
                </div>
            )}
            {hasError && (
                <SimpleInfoBox
                    withBorderRadius
                    className="ba-list-page__error"
                    state={ComponentState.ERROR}
                    text={{ textKey: 'somethingWentWrong' }}
                />
            )}
            {damageAppraiserValuations.length > 0 && !hasFinished && (
                <Button
                    className="ba-list-page__button"
                    textKey="showMore"
                    onClick={() => loadDamageAppraisers()}
                    buttonType={ButtonType.SECONDARY}
                />
            )}
            {hasFinished && (
                <DamageNoResults
                    damageAppraiserValuations={damageAppraiserValuations}
                />
            )}
        </Page>
    );
};

export default DamageListPage;
