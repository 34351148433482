import { Page, SimpleInfoBox } from '@carcare/components';
import Header from '../header';
import Menu from '../menu/Menu';
import { ComponentState } from '@carcare/components/dist/styles';
import React from 'react';

const MsalError = () => {
    return (
        <Page>
            <div className="ba-header-override__unauthorized">
                <Header />
            </div>
            <Menu isMenuVisible={false} />
            <SimpleInfoBox
                text={{
                    textKey: 'msalError',
                }}
                state={ComponentState.ERROR}
                withBorderRadius
            />
        </Page>
    );
};
export default MsalError;
