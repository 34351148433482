import React from 'react';

const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadMedia: (media: { file: string; name: string }) => Promise<void>,
    setIsLoading: (value: boolean) => void,
    setInvalidUpload: (value: boolean) => void
) => {
    const { files } = event.target;
    const reader = new FileReader();
    if (files == null || files.length === 0) {
        return;
    }
    const file = files[0];
    if (!file.type.match('pdf')) {
        setInvalidUpload(true);
    } else {
        setIsLoading(true);
        reader.addEventListener('load', () => {
            uploadMedia({
                file:
                    reader.result
                        ?.toString()
                        .replace(`data:application/pdf;base64,`, '') ?? '',
                name: file.name,
            })
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        });
        reader.readAsDataURL(file);
    }
};

export default handleFileUpload;
