import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_DAMAGE_APPRAISERS } from '../endpoints';
import DamageValuationState from '../../utils/enums/DamageValuationState';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';

const searchDamageAppraisers = (
    setSearchResults: (value: DamageAppraiserValuation[]) => void,
    licensePlate: string,
    setIsLoading: (value: boolean) => void,
    setError: (value: string) => void,
    setNoResults: (value: boolean) => void
) => {
    const { request } = get(
        composeUrl(
            GET_DAMAGE_APPRAISERS,
            DamageValuationState.ALL,
            licensePlate,
            '100',
            '0'
        )
    );
    setIsLoading(true);
    request
        .then((response) => {
            setSearchResults(response.data as DamageAppraiserValuation[]);
            setIsLoading(false);
            if (response.data.length === 0) setNoResults(true);
        })
        .catch(() => {
            setError('somethingWentWrong');
            setIsLoading(false);
        });
};

export default searchDamageAppraisers;
