import ActionType from './ActionType';
import {
    SetCompletedDamageValuations,
    SetCompletedDamageValuationsLoading,
    SetCompletedDamageValuationsError,
    SetCompletedDamageValuationsFinished,
} from './types';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

export const setCompletedDamageValuations = (
    damageAppraiserValuations: DamageAppraiserValuation[]
): SetCompletedDamageValuations => ({
    type: ActionType.SET_COMPLETED_DAMAGE_VALUATIONS,
    payload: damageAppraiserValuations,
});

export const setCompletedDamageValuationsLoading = (
    value: boolean
): SetCompletedDamageValuationsLoading => ({
    type: ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_LOADING,
    payload: value,
});

export const setCompletedDamageValuationsError = (
    value: boolean
): SetCompletedDamageValuationsError => ({
    type: ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_ERROR,
    payload: value,
});

export const setCompletedDamageValuationsFinished = (
    value: boolean
): SetCompletedDamageValuationsFinished => ({
    type: ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_FINISHED,
    payload: value,
});
