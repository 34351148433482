import React from 'react';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import DamageValuationStatus from '../../../../utils/enums/DamageValuationStatus';
import InfoItem from '../info-item/InfoItem';
import { useTranslation } from 'react-i18next';
import licensePlateFallback from '../../../../utils/licensePlateFallback';
import { ManageRequest } from './ManageRequest';

interface RequestInfoHeaderProps {
    readonly damageValuation: DamageAppraiserValuation;
}

const RequestInfoHeader = ({ damageValuation }: RequestInfoHeaderProps) => {
    const [translate] = useTranslation();
    const isRequestAssigned = () => {
        if (
            damageValuation?.status === DamageValuationStatus.NEW &&
            (!damageValuation?.surveyor?.length ||
                damageValuation?.surveyor?.length === 0)
        ) {
            return translate('notOngoingRequest');
        }
        return damageValuation?.surveyor;
    };

    return (
        <>
            <div className="ba-request-info-header">
                <div className="ba-request-info__wrapper">
                    <InfoItem
                        label="surveyor"
                        value={isRequestAssigned()}
                        withBorder
                        italicValue={
                            damageValuation?.status ===
                            DamageValuationStatus.NEW
                        }
                    />
                    <InfoItem
                        label="car"
                        value={licensePlateFallback(damageValuation)}
                        withBorder
                        withCopyClick
                    />
                    <InfoItem
                        label="customer"
                        value={`${damageValuation.customer?.fullName ?? ''}
                        ${damageValuation.customer?.phone.phoneNumber ?? ''}
                        ${damageValuation.customer?.email ?? ''}
                        `}
                    />
                </div>
                <ManageRequest {...{ damageValuation }} />
            </div>
        </>
    );
};

export default RequestInfoHeader;
