import { getCurrentEnv } from 'src/env';
import environments from './environments';

const getCurrentEnvironment = (): { url: string; name: string } => {
    const originEnvironment = `${window.location.origin}`;
    const env = getCurrentEnv();
    if (['local-server'].includes(env)) {
        return {
            url: originEnvironment,
            name: 'local',
        };
    }

    const environment = environments.find((e) => e.name === env);
    if (!environment) {
        throw new Error('no env');
    }

    return environment;
};

export default getCurrentEnvironment;
