/* eslint-disable */ // TODO: solve this later
import { SessionStorage } from '../../utils/enums/SessionStorage';

export const loadImageWithAuth = (url: string, signal: AbortSignal) =>
    new Promise<string>(async (resolve, _) => {
        const headers = {
            Authorization: `Bearer ${sessionStorage.getItem(
                SessionStorage.ID_TOKEN
            )}`,
        };
        try {
            const res = await fetch(url, { headers: headers, signal });
            const blob = await res.blob();
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        } catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') {
                return;
            }
            throw e;
        }
    });
/* eslint enable */
