interface Token {
    sub: string;
    exp: number;
}

export const isExpired = (jwt: string): boolean => {
    const claims = parseToken(jwt);
    const epochNow = new Date().getTime() / 1000;
    return claims?.exp <= epochNow - 10;
};

const base64ToUtf8 = (str: string) => decodeURIComponent(window.atob(str));

const parseToken = (jwt: string): Token => {
    const encoded = jwt && jwt?.split('.')[1];
    const jsonString = base64ToUtf8(encoded);
    return JSON.parse(jsonString);
};
