import CompletedReason from '../../../../utils/enums/CompletedReason';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import {
    initialStatusRequest,
    RequestStatusText,
} from '../../types/RequestStatusText';

const valuationCompletedText = (
    appraiser: DamageAppraiserValuation
): RequestStatusText => {
    switch (appraiser?.completedReason) {
        case CompletedReason.CUSTOMER_ACCEPTED: {
            return { ...initialStatusRequest, textKey: 'customerAccepted' };
        }
        case CompletedReason.CUSTOMER_NO_RESPONSE: {
            return { ...initialStatusRequest, textKey: 'customerNoResponse' };
        }
        case CompletedReason.CUSTOMER_DECLINED: {
            return { ...initialStatusRequest, textKey: 'customerDeclined' };
        }
        case CompletedReason.APPRAISE_AT_DAMAGE_CENTER: {
            return {
                ...initialStatusRequest,
                textKey: 'appraiseAtDamageCenter',
            };
        }
        case CompletedReason.CONDEMNED: {
            return { ...initialStatusRequest, textKey: 'carCondemned' };
        }
        case CompletedReason.CASH_SETTLEMENT: {
            return { ...initialStatusRequest, textKey: 'cashSettlement' };
        }
        case CompletedReason.INVALID_APPRAISER: {
            return { ...initialStatusRequest, textKey: 'invalidAppraiser' };
        }
        default:
            return { ...initialStatusRequest };
    }
};

export default valuationCompletedText;
