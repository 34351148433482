const formatMessageTemplate = (
    templateText: string,
    values: Record<string, { toString: () => string } | undefined>
) =>
    templateText?.replace(
        /{ *([^} ]+) *}/gm,
        (placeholder) =>
            values[placeholder.slice(1, -1)]?.toString() || placeholder
    );

export default formatMessageTemplate;
