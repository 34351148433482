import { useEffect, useState } from 'react';
import cn from 'classnames';
import {
    Clickable,
    Icon,
    Text,
    SearchInput,
    Page,
    Spinner,
} from '@carcare/components';
import { clear } from '@carcare/components/dist/icons';
import { ComponentState } from '@carcare/components/dist/styles';
import searchDamageAppraisers from '../../http/carcareApi/searchDamageAppraisers';
import DamageList from '../damage-list/DamageList';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';

const Search = () => {
    const [searchResults, setSearchResults] = useState<
        DamageAppraiserValuation[]
    >([]);
    const [searchInput, setSearchInput] = useState('');
    const [inputHasFocus, setInputFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noResults, setNoResults] = useState(false);

    const activeSearch =
        inputHasFocus || searchInput?.length > 0 || errorMessage || noResults;

    const resetSearch = () => {
        setSearchInput('');
        setErrorMessage('');
        setIsLoading(false);
        setNoResults(false);
        setInputFocused(false);
    };

    const searchResult =
        searchResults.length > 0 && activeSearch ? (
            <div className="ba-search__page__results">
                <DamageList
                    damageAppraiserValuations={searchResults}
                    listTitle="searchResult"
                />
            </div>
        ) : null;

    useEffect(() => {
        if (searchInput.length === 7)
            searchDamageAppraisers(
                setSearchResults,
                searchInput,
                setIsLoading,
                setErrorMessage,
                setNoResults
            );
        else if (searchResults.length) setSearchResults([]);
        if (searchInput.length > 7) setErrorMessage('searchRegNoFormat');
    }, [searchInput]);

    return (
        <div className="ba-search">
            <Page
                className={cn('ba-search__page', {
                    'ba-search__page--active': activeSearch,
                })}
            >
                <SearchInput
                    value={searchInput}
                    placeholder="searchPlaceholder"
                    onChange={(e) => {
                        setNoResults(false);
                        setErrorMessage('');
                        setSearchInput(e.target.value.toUpperCase().trim());
                    }}
                    reset={() => resetSearch()}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                />
                <Clickable
                    onClick={() => resetSearch()}
                    className="ba-search__page__clear"
                >
                    <Icon file={clear} />
                </Clickable>
                {errorMessage && (
                    <Text
                        className="ba-search__page__text"
                        textKey={errorMessage}
                        state={ComponentState.ERROR}
                    />
                )}
                {!errorMessage && noResults && (
                    <Text
                        className="ba-search__page__text"
                        textKey="searchNoResults"
                        state={ComponentState.INFO}
                    />
                )}
                {!errorMessage && isLoading ? (
                    <div className="ba-search__page__loader">
                        <Spinner delayMillis={300} />
                    </div>
                ) : (
                    searchResult
                )}
            </Page>
            {activeSearch && <div className="ba-search__overlay" />}
        </div>
    );
};

export default Search;
