import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_DAMAGE_APPRAISERS } from '../endpoints';
import DamageValuationState from '../../utils/enums/DamageValuationState';
import { AnyAction, Dispatch } from 'redux';
import DamageAppraiserValuation from 'src/types/DamageAppraiserValuation';

interface FetchDamageAppraisersProps {
    readonly dispatch: Dispatch;
    readonly setIsLoading: (value: boolean) => AnyAction;
    readonly setHasError: (value: boolean) => AnyAction;
    readonly setHasFinished: (value: boolean) => AnyAction;
    readonly setDamageAppraiserValuations: (
        value: DamageAppraiserValuation[]
    ) => AnyAction;
    readonly state: DamageValuationState;
    readonly limit: string;
    readonly offset: number;
}

const fetchDamageAppraisers = ({
    dispatch,
    setIsLoading,
    setHasError,
    setHasFinished,
    setDamageAppraiserValuations,
    state,
    limit,
    offset,
}: FetchDamageAppraisersProps) => {
    dispatch(setIsLoading(true));
    dispatch(setHasError(false));
    const { request } = get(
        composeUrl(GET_DAMAGE_APPRAISERS, state, '', limit, offset)
    );
    request
        .then((response) => {
            dispatch(setDamageAppraiserValuations(response.data));
            dispatch(setIsLoading(false));
            if (response.data.length === 0 || response.data.length < limit) {
                dispatch(setHasFinished(true));
            }
        })
        .catch(() => {
            dispatch(setHasError(true));
            dispatch(setIsLoading(false));
        });
};

export default fetchDamageAppraisers;
