import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_DAMAGE_VALUATION } from '../endpoints';
import {
    setDamageValuationError,
    setDamageValuationLoading,
    setDamageValuation,
} from '../../store/damage/request/actions';
import { Dispatch } from 'redux';

interface FetchDamageValuationProps {
    readonly dispatch: Dispatch;
    readonly hash: string;
}

const fetchDamageValuation = ({
    dispatch,
    hash,
}: FetchDamageValuationProps) => {
    dispatch(setDamageValuationLoading(true));
    dispatch(setDamageValuationError(false));
    const { request } = get(composeUrl(GET_DAMAGE_VALUATION, hash));
    request
        .then((response) => {
            dispatch(setDamageValuation(response.data));
            dispatch(setDamageValuationLoading(false));
        })
        .catch(() => {
            dispatch(setDamageValuationError(true));
            dispatch(setDamageValuationLoading(false));
        });
};

export default fetchDamageValuation;
