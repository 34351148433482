import { UPDATE_DAV_STATUS } from '../endpoints';
import composeUrl from '../utils/composeUrl';
import post from '../post';
import DamageValuationStatus from '../../utils/enums/DamageValuationStatus';

const updateDavStatus = async (
    externalId: string | number,
    status: DamageValuationStatus
) => {
    const body = {
        davStatus: status,
    };

    return post(composeUrl(`${UPDATE_DAV_STATUS}`, externalId), body);
};

export default updateDavStatus;
