import Axios, { AxiosResponse } from 'axios';
import initialHttpState, { HttpState } from './utils/HttpState';

/* eslint-disable */
const handleResponse = (
    res: Promise<AxiosResponse>,
    path: string,
    setHttpState = (nextState: HttpState) => {
        return;
    }
) =>
    res
        .then((response) => {
            setHttpState({
                ...initialHttpState,
                isSuccess: true,
            });

            return response;
        })
        .catch((err: unknown) => {
            if (Axios.isCancel(err)) {
                setHttpState(initialHttpState);
            } else {
                setHttpState({
                    ...initialHttpState,
                    isFailed: true,
                });
            }

            throw err;
        });

/* eslint-enable */
export default handleResponse;
