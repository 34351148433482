import {
    SetInitialState,
    SetUserDetails,
    SetUserRole,
    SetLoginError,
} from './types';
import ActionType from './ActionType';

export const setLoginError = (errorMessage: string): SetLoginError => ({
    type: ActionType.SET_LOGIN_ERROR,
    payload: errorMessage,
});

export const setUserDetails = (
    name: string,
    username: string
): SetUserDetails => ({
    type: ActionType.SET_USER_DETAILS,
    payload: { name, username },
});

export const setRole = (role: string): SetUserRole => ({
    type: ActionType.SET_USER_ROLE,
    payload: role,
});

export const resetState = (): SetInitialState => ({
    type: ActionType.SET_INITIAL_STATE,
});
