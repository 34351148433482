import { getEnvVariable } from '../../../env';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

const bilholdLink = getEnvVariable('CARCARE_URL');

const messageTemplateData = (
    damageValuation: DamageAppraiserValuation,
    loggedInUser: { name: string; username: string },
    inputValues: { date: string; duration: string }
) => ({
    id: damageValuation?.id,
    vin: damageValuation?.car?.vin,
    dealerName: damageValuation?.dealer?.dealerName,
    dealerAddress: damageValuation?.dealer?.streetAdress,
    dealerNumber: damageValuation?.dealer?.dealerNumber,
    initials: loggedInUser?.name,
    link: `[Oppdater info her](${bilholdLink}/damage/${damageValuation?.damageAppraiserId})`,
    ...inputValues,
});

export default messageTemplateData;
