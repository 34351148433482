import React from 'react';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import { Box, HeaderText, HorizontalRule, Text } from '@carcare/components';
import { ComponentState, ComponentType } from '@carcare/components/dist/styles';
import CopyText from '../../../../components/copy-text/CopyText';
import InfoItem from '../info-item/InfoItem';
import RequestInfoHeader from '../request-info-header/RequestInfoHeader';
import { useTranslation } from 'react-i18next';
import RequestImages from '../request-images/RequestImages';

interface RequestInfoProps {
    readonly damageValuation: DamageAppraiserValuation;
    readonly hash: string;
}

const RequestInfo = ({ damageValuation, hash }: RequestInfoProps) => {
    const [translate] = useTranslation();

    return (
        <div className="ba-request-info">
            <RequestInfoHeader damageValuation={damageValuation} />
            <div className="ba-request-info__damage">
                <HeaderText
                    textKey="damage"
                    className="ba-request-info__damage__title"
                />
                <div>
                    <Box
                        componentType={ComponentType.COLORED_CONTAINER}
                        state={ComponentState.PRIMARY}
                        className="ba-request-info__damage__description"
                    >
                        <div className="ba-request-info__damage__description__header">
                            <Text
                                textKey="descriptionOfDamage"
                                className="ba-request-info__damage__description-title"
                            />
                            <CopyText
                                text={
                                    damageValuation?.damageAppraiser
                                        ?.description
                                }
                            />
                        </div>
                        <Text
                            textContent={
                                damageValuation?.damageAppraiser?.description
                            }
                        />
                    </Box>
                </div>
                <RequestImages hash={hash} damageValuation={damageValuation} />
            </div>
            <div className="ba-request-info__wrapper">
                <InfoItem
                    label="insuranceCompany"
                    value={
                        damageValuation?.damageAppraiser?.insuranceCompanyName
                    }
                />
                <InfoItem
                    label="insuranceNumber"
                    value={damageValuation?.damageAppraiser?.insuranceNumber}
                />
                <InfoItem
                    label="rentalCar"
                    value={
                        damageValuation?.damageAppraiser?.needsReplacementCar
                            ? translate('yes')
                            : translate('no')
                    }
                    uppercaseValue
                />
            </div>
            <HorizontalRule />
        </div>
    );
};

export default RequestInfo;
