import React from 'react';
import { Text } from '@carcare/components';
import { StatisticsListRow } from '../../utils/statisticsListItems';
import cn from 'classnames';

interface StatisticsListInterface {
    readonly statusItemList: StatisticsListRow[];
    readonly topElementShade?: boolean;
}

const StatisticsList = ({
    statusItemList,
    topElementShade,
}: StatisticsListInterface) => {
    return (
        <div className="ba-statistics__details__container">
            {statusItemList?.map((row) => (
                <div
                    className={cn('ba-statistics__details__content', {
                        'ba-statistics__details__content--topElementShade':
                            topElementShade,
                    })}
                    key={row?.key}
                >
                    <Text textKey={row?.key} />
                    <Text
                        className="ba-statistics__details__content-value"
                        textContent={`${row?.value}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default StatisticsList;
