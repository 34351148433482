import React, { useEffect, useState } from 'react';
import DamageStatisticsFilter from './components/damage-statistics-filter/DamageStatisticsFilter';
import {
    Page,
    Text,
    HorizontalRule,
    SimpleInfoBox,
    LoadingOverlay,
} from '@carcare/components';
import { PATH_DAMAGE } from '../../router/routes';
import PageLink from 'src/components/page-link/PageLink';
import Dealer from '../../types/Dealer';
import { useDispatch } from '../../store/useStore';
import fetchDamageCenters from '../../http/carcareApi/fetchDamageCenters';
import fetchDamageStatistics from '../../http/carcareApi/fetchDamageStatistics';
import DamageStatisticsData from '../../types/DamageStatistics';
import DamageStatisticsContent from './components/damage-statistics/DamageStatisticsContent';
import { ComponentState } from '@carcare/components/dist/styles';

interface DamageStatisticsProps {
    readonly damageCenters: Dealer[];
    readonly hasDamageCentersError: boolean;
    readonly isDamageCentersLoading: boolean;
}

const DamageStatistics = ({
    damageCenters,
    hasDamageCentersError,
    isDamageCentersLoading,
}: DamageStatisticsProps) => {
    const dispatch = useDispatch();
    const [damageCenter, setDamageCenter] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState<DamageStatisticsData>();
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');

    useEffect(() => {
        fetchDamageCenters({ dispatch });
    }, [dispatch]);

    useEffect(() => {
        if (damageCenter === '') {
            return;
        }
        fetchDamageStatistics(
            damageCenter === 'all' ? '' : damageCenter,
            fromDate,
            toDate,
            setData,
            setIsLoading,
            setHasError
        );
    }, [damageCenter, fromDate, toDate]);

    const selectedDamageCenter = damageCenters.find(
        (dealer) => dealer.dealerNumber === damageCenter
    );
    const choseAll = damageCenter === 'all';

    return (
        <>
            {isDamageCentersLoading && isLoading ? (
                <LoadingOverlay delayMillis={0} />
            ) : (
                <Page className="ba-damage-statistics">
                    <PageLink
                        className="ba-damage-statistics__back"
                        path={PATH_DAMAGE}
                        textKey="back"
                        isBack
                    />
                    <Text
                        className="ba-damage-statistics__title"
                        textKey="damageStatisticsTitle"
                    />
                    {!hasDamageCentersError && !isDamageCentersLoading && (
                        <DamageStatisticsFilter
                            damageCenters={damageCenters}
                            damageCenter={damageCenter}
                            setDamageCenter={setDamageCenter}
                            fromDate={fromDate}
                            toDate={toDate}
                            setFromDate={setFromDate}
                            setToDate={setToDate}
                        />
                    )}
                    {hasDamageCentersError && (
                        <SimpleInfoBox
                            state={ComponentState.ERROR}
                            text={{ textKey: 'requestDamageCentersError' }}
                        />
                    )}
                    {!hasDamageCentersError && <HorizontalRule />}

                    {!hasDamageCentersError && !hasError && data && (
                        <DamageStatisticsContent
                            isLoading={isLoading}
                            choseAll={choseAll}
                            selectedDamageCenter={selectedDamageCenter}
                            dealerStats={data}
                        />
                    )}

                    {hasError && (
                        <SimpleInfoBox
                            state={ComponentState.ERROR}
                            text={{ textKey: 'requestStatisticsError' }}
                        />
                    )}
                </Page>
            )}
        </>
    );
};

export default DamageStatistics;
