import DamageValuationStatus from '../../../utils/enums/DamageValuationStatus';
import { sentByDealerTimestamp } from './logUtils';
import { daysSince, hoursSince } from '../../../utils/time/timeUtils';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';
import valuationCompletedText from './status-texts/valuationCompletedText';
import valuationSentText from './status-texts/valuationSentText';
import valuationInProcessText from './status-texts/valuationInProcessText';
import valuationNewText from './status-texts/valuationNewText';
import {
    initialStatusRequest,
    RequestStatusText,
} from '../types/RequestStatusText';

const requestStatusText = (
    appraiser: DamageAppraiserValuation
): RequestStatusText | undefined => {
    switch (appraiser.status) {
        case DamageValuationStatus.NEW: {
            return valuationNewText(appraiser);
        }
        case DamageValuationStatus.PROCESSING:
        case DamageValuationStatus.RECEIVED_CHANGES: {
            return valuationInProcessText(appraiser);
        }
        case DamageValuationStatus.REQUESTED_CHANGES: {
            return {
                ...initialStatusRequest,
                textKey: 'customerNotAnsweredIn',
                hours: hoursSince(sentByDealerTimestamp(appraiser)),
                days: daysSince(sentByDealerTimestamp(appraiser)),
            };
        }
        case DamageValuationStatus.VALUATION_SENT: {
            return valuationSentText(appraiser);
        }
        case DamageValuationStatus.COMPLETED: {
            return valuationCompletedText(appraiser);
        }
    }
};

export default requestStatusText;
