import React from 'react';
import {
    setOngoingDamageValuations,
    setOngoingDamageValuationsError,
    setOngoingDamageValuationsFinished,
    setOngoingDamageValuationsLoading,
} from 'src/store/damage/ongoingRequests/actions';
import DamageListPage from '../../components/damage-list-page/DamageListPage';
import useStore from '../../store/useStore';
import DamageValuationState from '../../utils/enums/DamageValuationState';

const Index = () => {
    const [store] = useStore();
    const {
        damage: {
            ongoingRequests: {
                ongoingDamageValuations,
                ongoingDamageValuationsHasError,
                ongoingDamageValuationsHasFinished,
                ongoingDamageValuationsIsLoading,
            },
        },
    } = store;

    return (
        <DamageListPage
            listTitle="allOngoingRequests"
            damageAppraiserValuations={ongoingDamageValuations}
            isLoading={ongoingDamageValuationsIsLoading}
            hasFinished={ongoingDamageValuationsHasFinished}
            hasError={ongoingDamageValuationsHasError}
            setIsLoading={setOngoingDamageValuationsLoading}
            setHasError={setOngoingDamageValuationsError}
            setHasFinished={setOngoingDamageValuationsFinished}
            setDamageAppraiserValuations={setOngoingDamageValuations}
            state={DamageValuationState.PENDING}
        />
    );
};

export default Index;
