import React from 'react';
import { Page, SimpleInfoBox } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import Header from '../components/header';
import Menu from '../components/menu';
import AuthenticationGuard from '../http/azureAD/utils/AuthenticationGuard';

const DownForMaintenance = () => {
    return (
        <AuthenticationGuard>
            <Header />
            <Menu />
            <Page>
                <SimpleInfoBox
                    state={ComponentState.INFO}
                    text={{
                        textContent:
                            'Bilhold-admin er desverre ikke tilgjengelig for øyeblikket på grunn av planlagt vedlikehold tirsdag 29.mars fra 11.00 - 16.00. Vennligst prøv igjen senere.',
                    }}
                />
            </Page>
        </AuthenticationGuard>
    );
};

export default DownForMaintenance;
