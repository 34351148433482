import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_CODE } from './constants';
import translationsEN from './translations-en.json';
import translationsNO from './translations-no.json';

const NAMESPACE = 'transl';

i18n.use(languageDetector).init({
    // debug: true,

    // fallback depending on user language, ordered
    fallbackLng: {
        nb: [LANGUAGE_CODE.NORWEGIAN, LANGUAGE_CODE.ENGLISH],
        'nb-NO': [LANGUAGE_CODE.NORWEGIAN, LANGUAGE_CODE.ENGLISH],
        nn: [LANGUAGE_CODE.NORWEGIAN, LANGUAGE_CODE.ENGLISH],
        'nn-NO': [LANGUAGE_CODE.NORWEGIAN, LANGUAGE_CODE.ENGLISH],
        dev: [LANGUAGE_CODE.NORWEGIAN],
        default: [LANGUAGE_CODE.NORWEGIAN],
    },

    lng: LANGUAGE_CODE.NORWEGIAN,

    load: 'languageOnly',

    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    react: {
        wait: true,
        defaultTransParent: 'span', // a valid react element - required before react 16
    },

    resources: {
        en: {
            [NAMESPACE]: translationsEN,
        },
        no: {
            [NAMESPACE]: translationsNO,
        },
    },

    // have a common namespace used around the full app
    ns: [NAMESPACE],
    defaultNS: NAMESPACE,

    // Options for language detection
    detection: {
        // order and from where user language should be detected
        order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],

        // keys or params to lookup language from
        lookupCookie: 'lang',
        lookupLocalStorage: 'language',

        // cache user language on
        caches: ['cookie', 'localStorage'],
    },
});

export default i18n;
