import { Dispatch, useEffect } from 'react';
import { AnyAction } from 'redux';
import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_THREADS_FOR_GROUPS } from '../endpoints';

import {
    SetThreadsError,
    SetThreadsLoading,
    setThreads,
    SetThreadsFinished,
} from 'src/store/messages/actions';
import useStore from 'src/store/useStore';
import { getCurrentEnv } from 'src/env';

export function useFetchAllThreads() {
    const [store, dispatch] = useStore();
    const env = getCurrentEnv();
    const participants = env === 'prod' ? ['G006'] : ['G050'];

    const {
        messages: { threadsIsLoading, threadsHasError, threadsHasFinished },
    } = store;

    const shouldFetch =
        !threadsHasFinished && !threadsIsLoading && !threadsHasError;

    useEffect(() => {
        if (shouldFetch) {
            fetchAllThreads(participants, dispatch);
        }
    }, [dispatch, participants, shouldFetch]);
}

const fetchAllThreads = (
    participantIds: string[],
    dispatch: Dispatch<AnyAction>
) => {
    dispatch(SetThreadsError(false));
    dispatch(SetThreadsLoading(true));
    return get(composeUrl(GET_THREADS_FOR_GROUPS, participantIds.toString()))
        .request.then((res) => {
            dispatch(setThreads(res.data));
            dispatch(SetThreadsFinished(true));
            dispatch(SetThreadsLoading(false));
        })
        .catch(() => {
            dispatch(SetThreadsError(true));
            dispatch(SetThreadsFinished(false));
            dispatch(SetThreadsLoading(false));
        });
};
