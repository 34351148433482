import ActionType from './ActionType';
import {
    SetFetchedThreads,
    SetFetchedThreadsLoading,
    SetFetchedThreadsError,
    SetFetchedThreadsFinished,
} from './types';
import { Thread } from 'src/http/message-center/types/generated/MessageCenterTypes';

export const setThreads = (
    damageAppraiserValuations: Thread[]
): SetFetchedThreads => ({
    type: ActionType.SET_FETCH_ALL_THREADS,
    payload: damageAppraiserValuations,
});

export const SetThreadsLoading = (
    value: boolean
): SetFetchedThreadsLoading => ({
    type: ActionType.SET_FETCH_ALL_THREADS_LOADING,
    payload: value,
});

export const SetThreadsError = (value: boolean): SetFetchedThreadsError => ({
    type: ActionType.SET_FETCH_ALL_THREADS_ERROR,
    payload: value,
});

export const SetThreadsFinished = (
    value: boolean
): SetFetchedThreadsFinished => ({
    type: ActionType.SET_FETCH_ALL_THREADS_FINISHED,
    payload: value,
});
