import { PublicClientApplication } from '@azure/msal-browser';
const msalInstance = new PublicClientApplication({
    auth: {
        authority:
            'https://login.microsoftonline.com/a1e38214-9521-4c2a-a29a-4fa0a4457a5c',
        clientId: '1ad6eb90-23b1-4019-a33b-a202aeb45ca1',
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
});
export default msalInstance;
