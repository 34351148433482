import composeUrl from '../utils/composeUrl';
import { POST_VALUATION_SURVEYOR } from '../endpoints';
import post from '../post';
import fetchDamageValuation from './fetchDamageValuation';
import {
    setNewMessageError,
    setNewMessageLoading,
} from 'src/store/damage/request/actions';
import { Dispatch } from 'redux';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';
import fetchValuationMessages from '../message-center/fetchThread';

export function postSetValuationSurveyor(
    id: number,
    data: {
        surveyor: string;
    },
    dispatch: Dispatch,
    damageValuation: DamageAppraiserValuation
) {
    post(composeUrl(POST_VALUATION_SURVEYOR, id), data)
        .then(() => {
            fetchDamageValuation({
                dispatch,
                hash: damageValuation?.hash,
            });
        })
        .catch(() => {
            dispatch(setNewMessageError('postSetSurveyorError'));
        });
}

const postValuationSurveyor = (
    id: number,
    participantId: string,
    data: {
        surveyor: string;
    },
    dispatch: Dispatch,
    damageValuation: DamageAppraiserValuation
) =>
    post(composeUrl(POST_VALUATION_SURVEYOR, id), data)
        .then(() => {
            fetchDamageValuation({
                dispatch,
                hash: damageValuation?.hash,
            });
            dispatch(setNewMessageLoading(false));
        })
        .catch(() => {
            fetchValuationMessages(
                damageValuation?.id,
                participantId,
                dispatch
            );
            dispatch(setNewMessageError('postSurveyorError'));
            dispatch(setNewMessageLoading(false));
        });

export default postValuationSurveyor;
