import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import fetchMessages from 'src/http/message-center/fetchMessages';
import useStore from 'src/store/useStore';
import { useFetchAllThreads } from 'src/http/message-center/fetchThreads';
import MessagePage from './MessagePage';

const ViewMessageIndex = () => {
    const [store, dispatch] = useStore();
    const history = useHistory();

    const {
        messages: { threads, threadsIsLoading, threadsHasError },
        damage: {
            request: {
                messages,
                messagesAreLoading,
                messagesHasError,
                newMessageIsLoading,
            },
        },
        account,
    } = store;
    const { threadId } = useParams<{ threadId: string }>();
    useFetchAllThreads();

    useEffect(() => {
        fetchMessages(threadId, 'mbh@moller.no', dispatch);
    }, []);

    return (
        <MessagePage
            hasError={threadsHasError}
            threadHasError={threadsHasError}
            messagesHasError={messagesHasError}
            history={history}
            messages={messages}
            loggedInUser={account.user}
            dataIsLoading={threadsIsLoading || messagesAreLoading}
            newMessageError=""
            thread={threads.find((thread) => thread.threadId === threadId)}
            createMessageIsLoading={newMessageIsLoading}
        />
    );
};

export default ViewMessageIndex;
