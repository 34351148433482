enum ActionType {
    //completedRequests
    SET_COMPLETED_DAMAGE_VALUATIONS = 'SET_COMPLETED_DAMAGE_VALUATIONS',
    SET_COMPLETED_DAMAGE_VALUATIONS_LOADING = 'SET_COMPLETED_DAMAGE_VALUATIONS_LOADING',
    SET_COMPLETED_DAMAGE_VALUATIONS_ERROR = 'SET_COMPLETED_DAMAGE_VALUATIONS_ERROR',
    SET_COMPLETED_DAMAGE_VALUATIONS_FINISHED = 'SET_COMPLETED_DAMAGE_VALUATIONS_FINISHED',

    RESET_DAMAGE_DASHBOARD = 'RESET_DAMAGE_DASHBOARD',
}

export default ActionType;
