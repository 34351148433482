import React, { useEffect } from 'react';
import { LoadingOverlay } from '@carcare/components';
import Request from './Request';
import fetchDamageValuation from '../../http/carcareApi/fetchDamageValuation';
import fetchThreadAndMessages from '../../http/message-center/fetchThread';
import { useHistory, useParams } from 'react-router';
import useStore from 'src/store/useStore';
import fetchMessageTemplates from 'src/http/carcareApi/fetchMessageTemplates';
import { setDamageValuationLoading } from 'src/store/damage/request/actions';

const Index = () => {
    const [store, dispatch] = useStore();
    const history = useHistory();
    const {
        damage: {
            request: {
                damageValuation,
                damageValuationIsLoading,
                damageValuationHasError,
                thread,
                threadIsLoading,
                threadHasError,
                messages,
                messagesAreLoading,
                messagesHasError,
                newMessageIsLoading,
                messageTemplates,
                newMessageError,
            },
        },
        account: { user },
    } = store;
    const { hash } = useParams<{ hash: string }>();

    useEffect(() => {
        if (!damageValuation || damageValuation?.hash !== hash) {
            dispatch(setDamageValuationLoading(true));
            fetchDamageValuation({ dispatch, hash });
        }
    }, [hash, damageValuation, dispatch]);

    useEffect(() => {
        if (damageValuation && damageValuation.hash === hash) {
            fetchThreadAndMessages(
                damageValuation?.damageAppraiserId,
                user.username,
                dispatch
            );
            fetchMessageTemplates({ dispatch, id: damageValuation?.id });
        }
    }, [hash, damageValuation, user, dispatch]);

    const damageValuationPending =
        (damageValuationIsLoading ||
            !damageValuation ||
            damageValuation?.hash !== hash) &&
        !damageValuationHasError;
    const threadPending =
        (threadIsLoading || (!thread && damageValuation)) && !threadHasError;
    const MessageTemplatePending =
        (newMessageIsLoading || !messageTemplates) && !newMessageError;

    if (
        damageValuationPending ||
        threadPending ||
        MessageTemplatePending ||
        messagesAreLoading
    )
        return <LoadingOverlay delayMillis={0} />;

    return (
        <Request
            damageValuation={damageValuation}
            thread={thread}
            messages={messages}
            hasError={damageValuationHasError}
            history={history}
            messageTemplates={messageTemplates}
            loggedInUser={user}
            newMessageError={newMessageError}
            messagesHasError={messagesHasError}
            threadHasError={threadHasError}
            hash={hash}
        />
    );
};

export default Index;
