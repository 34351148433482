import React, { useState } from 'react';
import {
    Text,
    LoadingOverlay,
    RadioButton,
    Modal,
    Button,
    ButtonType,
    SimpleInfoBox,
} from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import completedReasons from '../../utils/completedReasons';
import updateDamageValuation from '../../../../http/carcareApi/updateDamageValuation';
import DamageValuationStatus from '../../../../utils/enums/DamageValuationStatus';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../store/useStore';
import { resetDamageDashboard } from '../../../../store/damage/request/actions';
import CompletedReason from 'src/utils/enums/CompletedReason';
import historyBackRoute from '../../../../utils/browser/historyBackRoute';

interface CompleteModalProps {
    readonly setModalShowing: (value: boolean) => void;
    readonly id: number;
    readonly show: boolean;
}

const CompleteModal = ({ show, setModalShowing, id }: CompleteModalProps) => {
    const [reason, setReason] = useState<CompletedReason>();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const completeRequest = () => {
        setIsLoading(true);
        setHasError(false);
        updateDamageValuation(id, {
            completedReason: reason,
            status: DamageValuationStatus.COMPLETED,
        })
            .then(() => {
                dispatch(resetDamageDashboard());
                historyBackRoute(history);
                setIsLoading(false);
            })
            .catch(() => {
                setHasError(true);
                setIsLoading(false);
            });
    };

    if (!show) {
        return null;
    }

    return (
        <Modal
            className="ba-complete-modal"
            close={() => setModalShowing(false)}
            closeIcon
        >
            {isLoading && <LoadingOverlay delayMillis={0} />}
            <Text
                className="ba-complete-modal__title"
                textKey="completeRequestTitle"
            />
            {completedReasons.map((completedReason) => (
                <RadioButton
                    key={completedReason.reason}
                    name="completeRequest"
                    title={{ textKey: completedReason.labelKey }}
                    onChange={() => {
                        setHasError(false);
                        setReason(completedReason.reason);
                    }}
                    checked={reason === completedReason.reason}
                />
            ))}
            {hasError && (
                <SimpleInfoBox
                    className="ba-complete-modal__error"
                    state={ComponentState.ERROR}
                    text={{ textKey: 'somethingWentWrong' }}
                />
            )}
            <div className="ba-complete-modal__buttons">
                <Button
                    textKey="cancel"
                    buttonType={ButtonType.SECONDARY}
                    onClick={() => setModalShowing(false)}
                />
                <Button
                    textKey="completeRequest"
                    onClick={() => completeRequest()}
                    disabled={!reason}
                />
            </div>
        </Modal>
    );
};

export default CompleteModal;
