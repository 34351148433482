import { isExpired, remainingHours } from '../../../../utils/time/timeUtils';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import {
    initialStatusRequest,
    RequestStatusText,
} from '../../types/RequestStatusText';

const valuationNewText = (
    appraiser: DamageAppraiserValuation
): RequestStatusText => {
    if (isExpired(appraiser?.answerDeadlineAt)) {
        return {
            ...initialStatusRequest,
            textKey: 'newRequestExpired',
            isBold: true,
        };
    } else {
        return {
            ...initialStatusRequest,
            textKey: 'newRequestAnswerWithin',
            hours: remainingHours(appraiser?.answerDeadlineAt),
        };
    }
};

export default valuationNewText;
