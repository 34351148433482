import {
    ActionFromReducersMapObject,
    combineReducers,
    StateFromReducersMapObject,
} from 'redux';
import completedRequestsReducer from './completedRequests/completedRequestsReducer';
import damageStatisticsReducer from './damageStatistics/damageStatisticsReducer';
import newRequestReducer from './newRequest/newRequestReducer';
import ongoingRequestsReducer from './ongoingRequests/ongoingRequestsReducer';
import requestReducer from './request/requestReducer';

declare module 'react-redux' {
    interface DefaultRootState extends DamageState {}
}

export type DamageState = StateFromReducersMapObject<typeof reducers>;
export type DamageActions = ActionFromReducersMapObject<typeof reducers>;

const reducers = {
    damageStatistics: damageStatisticsReducer,
    newRequests: newRequestReducer,
    ongoingRequests: ongoingRequestsReducer,
    completedRequests: completedRequestsReducer,
    request: requestReducer,
};

const allReducers = combineReducers(reducers);

const damageReducer = (
    state: DamageState | undefined,
    action: DamageActions
) => {
    if (action.type === 'RESET_DAMAGE_DASHBOARD') {
        state = undefined;
    }
    return allReducers(state, action);
};

export default damageReducer;
