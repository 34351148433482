import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_DAMAGE_STATISTICS } from '../endpoints';
import DamageStatisticsData from '../../types/DamageStatistics';

const fetchDamageStatistics = (
    dealerNumber: string,
    fromDate: string,
    toDate: string,
    setData: (value: DamageStatisticsData) => void,
    setIsLoading: (value: boolean) => void,
    setHasError: (value: boolean) => void
) => {
    setIsLoading(true);
    setHasError(false);
    const { request } = get(
        composeUrl(GET_DAMAGE_STATISTICS, dealerNumber, fromDate, toDate)
    );
    request
        .then((response) => {
            setIsLoading(false);
            setData(response.data);
        })
        .catch(() => {
            setIsLoading(false);
            setHasError(true);
        });
};

export default fetchDamageStatistics;
