import ActionType from './ActionType';
import {
    SetOngoingDamageValuations,
    SetOngoingDamageValuationsError,
    SetOngoingDamageValuationsFinished,
    SetOngoingDamageValuationsLoading,
} from './types';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

export const setOngoingDamageValuations = (
    damageAppraiserValuations: DamageAppraiserValuation[]
): SetOngoingDamageValuations => ({
    type: ActionType.SET_ONGOING_DAMAGE_VALUATIONS,
    payload: damageAppraiserValuations,
});

export const setOngoingDamageValuationsLoading = (
    value: boolean
): SetOngoingDamageValuationsLoading => ({
    type: ActionType.SET_ONGOING_DAMAGE_VALUATIONS_LOADING,
    payload: value,
});

export const setOngoingDamageValuationsError = (
    value: boolean
): SetOngoingDamageValuationsError => ({
    type: ActionType.SET_ONGOING_DAMAGE_VALUATIONS_ERROR,
    payload: value,
});

export const setOngoingDamageValuationsFinished = (
    value: boolean
): SetOngoingDamageValuationsFinished => ({
    type: ActionType.SET_ONGOING_DAMAGE_VALUATIONS_FINISHED,
    payload: value,
});
