import { PATH_DAMAGE, PATH_DAMAGE_STATISTICS, PATH_HOME } from '../routes';

const currentPageName = (path: string): string => {
    if (path.includes(PATH_DAMAGE_STATISTICS)) {
        return PATH_DAMAGE_STATISTICS;
    }
    if (path.includes(PATH_DAMAGE)) {
        return PATH_DAMAGE;
    }
    if (path === PATH_HOME) {
        return PATH_DAMAGE;
    }
    return '';
};

export default currentPageName;
