import React from 'react';
import ListItem from './components/list-item/ListItem';
import ListHeader from './components/list-header/ListHeader';

interface ListProps {
    readonly listHeader: string[];
    readonly listItems: {
        items: {
            textKey?: string;
            textContent?: string;
            isBold?: boolean;
            hours?: string;
            days?: string;
        }[];
        withBackground: boolean;
        onClick?: () => void;
    }[];
}

const List = ({ listHeader, listItems }: ListProps) => {
    return (
        <table className="ba-list">
            <ListHeader listHeader={listHeader} />
            {listItems?.map((item, index) => (
                <ListItem
                    key={index}
                    listItem={item.items}
                    withBackground={item.withBackground}
                    onClick={item.onClick}
                />
            ))}
        </table>
    );
};

export default List;
