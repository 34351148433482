import ActionType from './ActionType';
import {
    SetDamageValuation,
    SetDamageValuationError,
    SetDamageValuationLoading,
    SetMessageTemplates,
    SetNewMessageLoading,
    SetNewMessageError,
    SetRequestMessages,
    SetRequestMessagesLoading,
    SetRequestMessagesError,
    SetRequestThread,
    SetRequestThreadLoading,
    SetRequestThreadError,
    ResetDamageDashboard,
} from './types';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';
import MessageTemplate from '../../../types/MessageTemplate';
import {
    Thread,
    Message,
} from '../../../http/message-center/types/generated/MessageCenterTypes';

export const setDamageValuation = (
    damageAppraiserValuation: DamageAppraiserValuation
): SetDamageValuation => ({
    type: ActionType.SET_DAMAGE_VALUATION,
    payload: damageAppraiserValuation,
});

export const setRequestMessages = (
    messages: Message[]
): SetRequestMessages => ({
    type: ActionType.SET_REQUEST_MESSAGES,
    payload: messages,
});

export const setRequestMessagesLoading = (
    value: boolean
): SetRequestMessagesLoading => ({
    type: ActionType.SET_REQUEST_MESSAGES_LOADING,
    payload: value,
});

export const setRequestMessagesError = (
    value: boolean
): SetRequestMessagesError => ({
    type: ActionType.SET_REQUEST_MESSAGES_ERROR,
    payload: value,
});

export const setRequestThread = (thread: Thread): SetRequestThread => ({
    type: ActionType.SET_REQUEST_THREAD,
    payload: thread,
});

export const setRequestThreadLoading = (
    value: boolean
): SetRequestThreadLoading => ({
    type: ActionType.SET_REQUEST_THREAD_LOADING,
    payload: value,
});

export const setRequestThreadError = (
    value: boolean
): SetRequestThreadError => ({
    type: ActionType.SET_REQUEST_THREAD_ERROR,
    payload: value,
});

export const setDamageValuationLoading = (
    value: boolean
): SetDamageValuationLoading => ({
    type: ActionType.SET_DAMAGE_VALUATION_LOADING,
    payload: value,
});

export const setDamageValuationError = (
    value: boolean
): SetDamageValuationError => ({
    type: ActionType.SET_DAMAGE_VALUATION_ERROR,
    payload: value,
});

export const setMessageTemplates = (
    messageTemplates: MessageTemplate[]
): SetMessageTemplates => ({
    type: ActionType.SET_MESSAGE_TEMPLATES,
    payload: messageTemplates,
});

export const setNewMessageLoading = (value: boolean): SetNewMessageLoading => ({
    type: ActionType.SET_NEW_MESSAGE_LOADING,
    payload: value,
});

export const setNewMessageError = (value: string): SetNewMessageError => ({
    type: ActionType.SET_NEW_MESSAGE_ERROR,
    payload: value,
});

export const resetDamageDashboard = (): ResetDamageDashboard => ({
    type: ActionType.RESET_DAMAGE_DASHBOARD,
});
