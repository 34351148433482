import { HeaderText } from '@carcare/components';
import List from 'src/components/list/List';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    readonly listItems: {
        items: {
            textKey?: string;
            textContent?: string;
            isBold?: boolean;
            hours?: string;
            days?: string;
        }[];
        withBackground: boolean;
        onClick?: () => void;
    }[];
}

export const MessageTable = ({ title, listItems }: Props) => {
    const [translate] = useTranslation();

    const listHeader = [
        translate('messageHeaderReg'),
        translate('messageHeaderName'),
        translate('messageHeaderExherpt'),
        translate('messageHeaderUpdated'),
        translate('messageHeaderStatus'),
        translate('messageHeaderResponsible'),
        translate('messageHeaderDealerName'),
    ];

    return (
        <div>
            <HeaderText className="ba-damage-list__header" textKey={title} />
            <List listItems={listItems} listHeader={listHeader} />
        </div>
    );
};
