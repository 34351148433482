export interface HttpState {
    isLoading: boolean;
    isSuccess: boolean;
    isFailed: boolean;
}

const initialHttpState: HttpState = {
    isFailed: false,
    isLoading: false,
    isSuccess: false,
};

export default initialHttpState;
