import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_MESSAGES } from '../endpoints';
import {
    setRequestMessages,
    setRequestMessagesError,
    setRequestMessagesLoading,
} from 'src/store/damage/request/actions';

const fetchMessages = (
    threadId: string,
    participantId: string,
    dispatch: Dispatch<AnyAction>
) => {
    dispatch(setRequestMessagesError(false));
    dispatch(setRequestMessagesLoading(true));
    get(composeUrl(GET_MESSAGES, threadId), {
        params: { type: 'IN_APP' },
        headers: { participantId },
    })
        .request.then((res) => {
            dispatch(setRequestMessages(res.data));
            dispatch(setRequestMessagesLoading(false));
        })
        .catch(() => {
            dispatch(setRequestMessagesError(true));
            dispatch(setRequestMessagesLoading(false));
        });
};

export default fetchMessages;
