import DamageStatisticsData from '../../../types/DamageStatistics';
import { countCompletedReason, countStatus } from './countStatistics';

export interface StatisticsListRow {
    readonly key: string;
    readonly value: number;
}

export const statusItemList = (dealerStats: DamageStatisticsData) => {
    const technicalDetailsValues: StatisticsListRow[] = [
        {
            key: 'ongoingRequests',
            value: countStatus(dealerStats),
        },
        { key: 'newRequests', value: dealerStats?.status?.NEW?.count },
        {
            key: 'ongoing',
            value: dealerStats?.status?.PROCESSING?.count,
        },
        {
            key: 'needMoreInfo',
            value: dealerStats?.status?.REQUESTED_CHANGES?.count,
        },
        { key: 'newInfo', value: dealerStats?.status?.RECEIVED_CHANGES?.count },
        {
            key: 'unansweredValuations',
            value: dealerStats?.status?.VALUATION_SENT?.count,
        },
    ];
    return technicalDetailsValues;
};

export const completedReasonItemList = (dealerStats: DamageStatisticsData) => {
    const technicalDetailsValues: StatisticsListRow[] = [
        {
            key: 'completedRequests',
            value: countCompletedReason(dealerStats),
        },
        {
            key: 'acceptedValuations',
            value: dealerStats?.completedReason?.CUSTOMER_ACCEPTED?.count,
        },
        {
            key: 'customerDeclinedStats',
            value: dealerStats?.completedReason?.CUSTOMER_DECLINED?.count,
        },
        {
            key: 'appraiseAtDamageCenter',
            value: dealerStats?.completedReason?.APPRAISE_AT_DAMAGE_CENTER
                ?.count,
        },
        {
            key: 'customerNotResponding',
            value: dealerStats?.completedReason?.CUSTOMER_NO_RESPONSE?.count,
        },
        {
            key: 'carCondemned',
            value: dealerStats?.completedReason?.CONDEMNED?.count,
        },
        {
            key: 'cashSettlementLabel',
            value: dealerStats?.completedReason?.CASH_SETTLEMENT?.count,
        },
        {
            key: 'invalidAppraiser',
            value: dealerStats?.completedReason?.INVALID_APPRAISER?.count,
        },
    ];
    return technicalDetailsValues;
};
