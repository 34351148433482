const totalPrice = (priceRecord: Record<string, string>) => {
    const prices = Object.values(priceRecord).map((p) =>
        p !== '' ? Number(p) : 0
    );
    if (prices.length > 0) {
        return prices.reduce((acc, curr) => acc + curr).toString();
    } else return '';
};

export default totalPrice;
