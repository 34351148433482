import React from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import MsalError from '../../../components/msal-error/MsalError';
import { LoadingOverlay } from '@carcare/components';

const MsalLoader = () => {
    return <LoadingOverlay delayMillis={0} />;
};

const AuthenticationGuard: React.FC = ({ children }) => {
    return (
        <MsalAuthenticationTemplate
            loadingComponent={MsalLoader}
            errorComponent={MsalError}
            interactionType={InteractionType.Redirect}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
};
AuthenticationGuard.displayName = 'AuthenticationGuard';
export default AuthenticationGuard;
