import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_VALUATION_MESSAGE_TEMPLATES } from '../endpoints';
import {
    setMessageTemplates,
    setNewMessageError,
    setNewMessageLoading,
} from '../../store/damage/request/actions';
import { Dispatch } from 'redux';

interface FetchMessageTemplatesProps {
    readonly dispatch: Dispatch;
    readonly id: number;
}

const fetchMessageTemplates = ({
    dispatch,
    id,
}: FetchMessageTemplatesProps) => {
    dispatch(setNewMessageLoading(true));
    dispatch(setNewMessageError(''));
    const { request } = get(composeUrl(GET_VALUATION_MESSAGE_TEMPLATES, id));
    request
        .then((response) => {
            dispatch(setMessageTemplates(response.data));
            dispatch(setNewMessageLoading(false));
        })
        .catch(() => {
            dispatch(setNewMessageError('fetchTemplatesError'));
            dispatch(setNewMessageLoading(false));
        });
};

export default fetchMessageTemplates;
