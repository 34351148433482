import { isExpired, remainingHours } from '../../../../utils/time/timeUtils';
import {
    isLastMessageFromCustomer,
    sentByCustomerAnswerWithin,
} from '../logUtils';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import {
    initialStatusRequest,
    RequestStatusText,
} from '../../types/RequestStatusText';

const valuationInProcessText = (
    appraiser: DamageAppraiserValuation
): RequestStatusText => {
    if (isLastMessageFromCustomer(appraiser)) {
        if (isExpired(sentByCustomerAnswerWithin(appraiser))) {
            return {
                ...initialStatusRequest,
                textKey: 'valuationSentExpired',
                isBold: true,
            };
        } else {
            return {
                ...initialStatusRequest,
                textKey: 'valuationSentAnswerWithin',
                hours: remainingHours(sentByCustomerAnswerWithin(appraiser)),
                isBold: true,
            };
        }
    } else {
        return {
            ...initialStatusRequest,
            textKey: 'processingRequestAnswerWithin',
        };
    }
};

export default valuationInProcessText;
