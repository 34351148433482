import React from 'react';
import { Icon, Text, IconText } from '@carcare/components';
import { happy, dissatisfied } from '@carcare/components/dist/icons/mood';
import { ComponentState } from '@carcare/components/dist/styles';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';

interface DamageNoResultsProps {
    readonly damageAppraiserValuations: DamageAppraiserValuation[];
    readonly noResultsTextKey?: string;
}

const DamageNoResults = ({
    damageAppraiserValuations,
    noResultsTextKey = 'noResults',
}: DamageNoResultsProps) => {
    return (
        <IconText
            className="ba-damage-no-results"
            text={
                <Text
                    textKey={
                        damageAppraiserValuations?.length > 0
                            ? 'noMoreResults'
                            : noResultsTextKey
                    }
                    state={ComponentState.INFO}
                />
            }
            icon={
                <Icon
                    file={
                        damageAppraiserValuations?.length > 0
                            ? happy
                            : dissatisfied
                    }
                    state={ComponentState.INFO}
                />
            }
        />
    );
};

export default DamageNoResults;
