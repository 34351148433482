import React, { useEffect, useState } from 'react';
import { loadImageWithAuth } from '../../../../../http/utils/authUtils';
import { Clickable, Spinner } from '@carcare/components';
import { CARCARE_SURVEYOR_API } from '../../../../../http/endpoints';
import DamageAppraiserValuation from 'src/types/DamageAppraiserValuation';

interface ImageFromLocalProps {
    damageValuation: DamageAppraiserValuation;
    imageAltText: string;
    imageId: string;
    hash: string;
}

const imageSize = 250;
const ImageFromLocal = ({
    damageValuation,
    imageAltText,
    imageId,
    hash,
}: ImageFromLocalProps) => {
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (damageValuation.hash === hash) {
            setIsLoading(true);
            loadImageWithAuth(
                `${CARCARE_SURVEYOR_API}/damageAppraiserValuations/id/${damageValuation.damageAppraiserId}/images/imageId/${imageId}`,
                signal
            )
                .then(setImageUrl)
                .finally(() => setIsLoading(false));
        }
        return () => {
            controller.abort();
        };
    }, [hash, damageValuation, imageId, setIsLoading]);

    const openDecodedImage = () => {
        const data = imageUrl;
        const w = window.open('about:blank');
        const image = new Image();
        image.src = data;
        setTimeout(() => {
            w?.document.write(image.outerHTML);
        }, 0);
    };

    return isLoading ? (
        <Spinner />
    ) : (
        <Clickable onClick={() => openDecodedImage()}>
            <img
                width={imageSize}
                height={imageSize}
                alt={imageAltText}
                src={imageUrl}
            />
        </Clickable>
    );
};

export default ImageFromLocal;
