import { SetToggleMenu, SetInitiated } from './types';
import ActionType from './ActionType';

export const setToggleMenu = (isVisible: boolean): SetToggleMenu => ({
    type: ActionType.SET_TOGGLE_MENU,
    payload: isVisible,
});

export const setInitiated = (isInitiated: boolean): SetInitiated => ({
    type: ActionType.SET_INITIATED,
    payload: isInitiated,
});
