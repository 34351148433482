import React from 'react';
import cn from 'classnames';
import { Icon, Clickable } from '@carcare/components';
import { copy } from '@carcare/components/dist/icons';
import { useTranslation } from 'react-i18next';

interface CopyTextProps {
    readonly text: string;
    readonly className?: string;
}

const CopyText = ({ text, className }: CopyTextProps) => {
    const [translate] = useTranslation();
    return (
        <Clickable
            className={cn('ba-copy-text', className)}
            onClick={() => navigator.clipboard.writeText(text)}
            title={translate('copyText')}
        >
            <Icon file={copy} title={translate('copyText')} />
        </Clickable>
    );
};

export default CopyText;
