import { Dispatch } from 'react';
import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_DAMAGE_CENTERS } from '../endpoints';
import {
    setDamageCentersError,
    setDamageCentersLoading,
    setDealers,
} from '../../store/damage/damageStatistics/actions';
import { AnyAction } from 'redux';

interface FetchDamageCentersProps {
    readonly dispatch: Dispatch<AnyAction>;
}

const fetchDamageCenters = ({ dispatch }: FetchDamageCentersProps) => {
    dispatch(setDamageCentersLoading(true));
    dispatch(setDamageCentersError(false));
    const { request } = get(composeUrl(GET_DAMAGE_CENTERS));
    request
        .then((response) => {
            dispatch(setDealers(response.data));
            dispatch(setDamageCentersLoading(false));
        })
        .catch(() => {
            dispatch(setDamageCentersError(true));
            dispatch(setDamageCentersLoading(false));
        });
};

export default fetchDamageCenters;
