export interface RequestStatusText {
    textKey: string;
    isBold: boolean;
    hours: string;
    days: string;
}
export const initialStatusRequest: RequestStatusText = {
    textKey: '',
    isBold: false,
    hours: '',
    days: '',
};
