import React from 'react';
import cn from 'classnames';
import { Text } from '@carcare/components';
import CopyText from '../../../../components/copy-text/CopyText';

interface InfoItemProps {
    readonly label: string;
    readonly value: string;
    readonly withCopyClick?: boolean;
    readonly withBorder?: boolean;
    readonly uppercaseValue?: boolean;
    readonly italicValue?: boolean;
}

const InfoItem = ({
    label,
    value,
    withCopyClick,
    withBorder,
    uppercaseValue,
    italicValue,
}: InfoItemProps) => {
    return (
        <div
            className={cn('ba-info-item', {
                'ba-info-item--with-border': withBorder,
            })}
        >
            <Text textKey={label} />
            <div
                className={cn('ba-info-item__value', {
                    'ba-info-item__value--uppercase': uppercaseValue,
                    'ba-info-item__value--italic': italicValue,
                })}
            >
                <Text textContent={value || '-'} />
                {withCopyClick && (
                    <CopyText text={value} className="ba-info-item__copy" />
                )}
            </div>
        </div>
    );
};

export default InfoItem;
