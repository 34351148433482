import React from 'react';
import cn from 'classnames';
import { Icon, IconTextLink, Text } from '@carcare/components';
import { chevron_left, chevron_right } from '@carcare/components/dist/icons';
import { ComponentState, FlexDirection } from '@carcare/components/dist/styles';

interface PageLinkProps {
    readonly path?: string;
    readonly textKey: string;
    readonly className?: string;
    readonly isBack?: boolean;
    readonly onClick?: () => void;
}

const PageLink = ({
    path,
    textKey,
    className,
    isBack = false,
    onClick = () => undefined,
}: PageLinkProps) => {
    return (
        <IconTextLink
            className={cn('ba-page-link', className)}
            direction={isBack ? FlexDirection.RIGHT : FlexDirection.LEFT}
            text={<Text textKey={textKey} state={ComponentState.INFO} />}
            icon={
                <Icon
                    file={isBack ? chevron_left : chevron_right}
                    state={ComponentState.INFO}
                />
            }
            link={path}
            onClick={onClick}
        />
    );
};

export default PageLink;
