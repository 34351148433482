import React, { useState } from 'react';
import moment from 'moment';
import { HeaderText, RadioButton } from '@carcare/components';
import { useDispatch } from '../../../../store/useStore';
import MessageTemplate from '../../../../types/MessageTemplate';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import formatMessageTemplate from '../../utils/formatMessageTemplate';
import messageTemplateData from '../../utils/messageTemplateData';
import NewMessageContent from '../new-message-content/NewMessageContent';
import { DATE_FORMAT } from '../../../../utils/time/timeFormat';
import MessageTemplateType from '../../../../utils/enums/MessageTemplateType';
import totalPrice from '../../utils/totalPrice';
import RequestError from '../request-error/RequestError';
import {
    Attachment as IAttachment,
    Thread,
} from '../../../../http/message-center/types/generated/MessageCenterTypes';

import {
    resetDamageDashboard,
    setNewMessageLoading,
} from '../../../../store/damage/request/actions';
import DamageValuationStatus from '../../../../utils/enums/DamageValuationStatus';

import postValuationPrice from '../../../../http/carcareApi/postValuationPrice';
import postMessage from '../../../../http/message-center/postMessage';
import postUserNotification from '../../../../http/carcareApi/postUserNotification';

interface NewMessageProps {
    readonly damageValuation: DamageAppraiserValuation;
    readonly templates: MessageTemplate[];
    readonly loggedInUser: { name: string; username: string };
    readonly newMessageError: string;
    readonly thread: Thread;
}

const NewMessage = ({
    damageValuation,
    templates,
    loggedInUser,
    newMessageError,
    thread,
}: NewMessageProps) => {
    const dispatch = useDispatch();
    const [messageTemplate, setMessageTemplate] = useState<MessageTemplate>();
    const [message, setMessage] = useState('');
    const [date, setDate] = useState('');
    const [estimatedFinished, setEstimatedFinished] = useState('');
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [prices, setPrices] = useState<Record<string, string>>({});

    const handleSetPrices = (
        event: React.ChangeEvent<HTMLInputElement>,
        attachmentFile: string
    ) => {
        const value = event.target.value;
        setPrices((prev: Record<string, string>) => ({
            ...prev,
            [attachmentFile]: value,
        }));
    };

    const handleSetAttachment = (newAttachment: IAttachment) => {
        if (attachments?.length === 0) {
            setAttachments([newAttachment]);
        } else if (
            attachments?.length > 0 &&
            !attachments.find((a) => a.filePath === newAttachment.filePath)
        ) {
            setAttachments([...attachments, newAttachment]);
        }
    };

    const updateValuationPrice = () => {
        return postValuationPrice(damageValuation?.id, totalPrice(prices));
    };

    const sendMessage = (newStatus: DamageValuationStatus) => {
        postMessage(
            dispatch,
            damageValuation,
            thread.threadId,
            attachments,
            loggedInUser,
            message,
            newStatus,
            () => {
                dispatch(resetDamageDashboard());
            }
        );
        if (damageValuation?.customer?.userId) {
            postUserNotification(
                damageValuation.customer.userId,
                thread.threadId ?? ''
            );
        }
    };

    const handleSendMessage = () => {
        let newStatus = DamageValuationStatus.PROCESSING;
        switch (messageTemplate?.type) {
            case MessageTemplateType.APPRAISER:
                newStatus = DamageValuationStatus.VALUATION_SENT;
                updateValuationPrice();
                break;
            case MessageTemplateType.INFORMATION_LACKING:
                newStatus = DamageValuationStatus.REQUESTED_CHANGES;
                break;
        }

        dispatch(setNewMessageLoading(true));
        sendMessage(newStatus);
    };

    return (
        <div className="ba-new-message">
            <HeaderText
                textKey="messageToCustomer"
                className="ba-new-message__title"
            />
            <div className="ba-new-message__wrapper">
                <div className="ba-new-message__wrapper__templates">
                    {templates?.map((template) => (
                        <RadioButton
                            key={template.type}
                            name="messageType"
                            title={{ textContent: template.name }}
                            onChange={() => {
                                setAttachments([]);
                                setMessageTemplate(template);
                                setMessage(
                                    formatMessageTemplate(
                                        template?.text,
                                        messageTemplateData(
                                            damageValuation,
                                            loggedInUser,
                                            {
                                                date: moment(date).format(
                                                    DATE_FORMAT
                                                ),
                                                duration:
                                                    moment(
                                                        estimatedFinished
                                                    ).format(DATE_FORMAT),
                                            }
                                        )
                                    )
                                );
                            }}
                            checked={messageTemplate?.type === template.type}
                        />
                    ))}
                </div>
                {messageTemplate && (
                    <NewMessageContent
                        damageValuation={damageValuation}
                        setMessage={setMessage}
                        message={message}
                        setDate={setDate}
                        date={date}
                        setEstimatedFinished={setEstimatedFinished}
                        estimatedFinished={estimatedFinished}
                        messageTemplate={messageTemplate}
                        loggedInUser={loggedInUser}
                        showEstimatedFinishedInput={
                            messageTemplate.type ===
                            MessageTemplateType.APPRAISER
                        }
                        showDateInput={
                            messageTemplate.type ===
                                MessageTemplateType.APPRAISER ||
                            messageTemplate.type ===
                                MessageTemplateType.TOO_DAMAGED
                        }
                        setAttachment={(attachment) =>
                            handleSetAttachment(attachment)
                        }
                        attachments={attachments}
                        handleSetPrices={handleSetPrices}
                        prices={prices}
                        totalPrice={totalPrice(prices)}
                        sendMessage={() => handleSendMessage()}
                        resetNewMessage={() => setMessageTemplate(undefined)}
                    />
                )}
            </div>
            {newMessageError && <RequestError errorMessage={newMessageError} />}
        </div>
    );
};
export default NewMessage;
