import DamageStatisticsData from '../../../types/DamageStatistics';

export const countStatus = (dealerStats: DamageStatisticsData) => {
    return (
        dealerStats?.status?.NEW?.count +
        dealerStats?.status?.PROCESSING?.count +
        dealerStats?.status?.REQUESTED_CHANGES?.count +
        dealerStats?.status?.RECEIVED_CHANGES?.count +
        dealerStats?.status?.VALUATION_SENT?.count
    );
};

export const countCompletedReason = (dealerStats: DamageStatisticsData) => {
    return (
        dealerStats?.completedReason?.CUSTOMER_ACCEPTED?.count +
        dealerStats?.completedReason?.CUSTOMER_NO_RESPONSE?.count +
        dealerStats?.completedReason?.CUSTOMER_DECLINED?.count +
        dealerStats?.completedReason?.APPRAISE_AT_DAMAGE_CENTER?.count +
        dealerStats?.completedReason?.CONDEMNED?.count +
        dealerStats?.completedReason?.CASH_SETTLEMENT?.count +
        dealerStats?.completedReason?.INVALID_APPRAISER?.count
    );
};

const formatNumberWithSpaces = (number: number): string =>
    new Intl.NumberFormat('no').format(Math.round(number));

export const countUnanswered = (dealerStats: DamageStatisticsData): string => {
    return formatNumberWithSpaces(dealerStats?.status?.VALUATION_SENT?.quote);
};

export const countAcceptedValuations = (
    dealerStats: DamageStatisticsData
): string => {
    return formatNumberWithSpaces(
        dealerStats?.completedReason?.CUSTOMER_ACCEPTED?.quote
    );
};
