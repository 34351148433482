import composeUrl from '../utils/composeUrl';
import { POST_VALUATION_ATTACHMENT } from '../endpoints';
import post from '../post';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { setNewMessageError } from 'src/store/damage/request/actions';

const postValuationAttachment = (
    id: number,
    dispatch: Dispatch<AnyAction>,
    data: {
        data: string;
        relatedId: number;
        userId: number;
    }
) => {
    dispatch(setNewMessageError(''));

    return post<typeof data, { fileName: string; fileType: 'PDF' | 'IMAGE' }>(
        composeUrl(POST_VALUATION_ATTACHMENT, id),
        data
    ).catch(() => {
        dispatch(setNewMessageError('somethingWentWrongContactUs'));
    });
};

export default postValuationAttachment;
