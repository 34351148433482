import { RequestTypes } from './types';
import ActionType from './ActionType';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';
import MessageTemplate from '../../../types/MessageTemplate';
import {
    Thread,
    Message,
} from '../../../http/message-center/types/generated/MessageCenterTypes';

export interface RequestState {
    damageValuation?: DamageAppraiserValuation;
    damageValuationIsLoading: boolean;
    damageValuationHasError: boolean;

    messageTemplates: MessageTemplate[];

    thread?: Thread;
    threadIsLoading: boolean;
    threadHasError: boolean;

    messages: Message[];
    messagesAreLoading: boolean;
    messagesHasError: boolean;

    newMessageError: string;
    newMessageIsLoading: boolean;
}

export const initialState: RequestState = {
    damageValuation: undefined,
    damageValuationIsLoading: false,
    damageValuationHasError: false,
    messageTemplates: [],
    thread: undefined,
    threadIsLoading: false,
    threadHasError: false,
    messages: [],
    messagesAreLoading: false,
    messagesHasError: false,
    newMessageIsLoading: false,
    newMessageError: '',
};

const requestReducer = (
    state: RequestState = initialState,
    action: RequestTypes
): RequestState => {
    switch (action.type) {
        case ActionType.SET_DAMAGE_VALUATION: {
            return {
                ...state,
                damageValuation: action.payload,
            };
        }
        case ActionType.SET_REQUEST_THREAD: {
            return {
                ...state,
                thread: action.payload,
            };
        }
        case ActionType.SET_REQUEST_THREAD_LOADING: {
            return {
                ...state,
                threadIsLoading: action.payload,
            };
        }
        case ActionType.SET_REQUEST_THREAD_ERROR: {
            return {
                ...state,
                threadHasError: action.payload,
            };
        }

        case ActionType.SET_REQUEST_MESSAGES: {
            return {
                ...state,
                messages: action.payload,
            };
        }
        case ActionType.SET_REQUEST_MESSAGES_LOADING: {
            return {
                ...state,
                messagesAreLoading: action.payload,
            };
        }
        case ActionType.SET_REQUEST_MESSAGES_ERROR: {
            return {
                ...state,
                messagesHasError: action.payload,
            };
        }

        case ActionType.SET_DAMAGE_VALUATION_LOADING: {
            return {
                ...state,
                damageValuationIsLoading: action.payload,
            };
        }

        case ActionType.SET_DAMAGE_VALUATION_ERROR: {
            return {
                ...state,
                damageValuationHasError: action.payload,
            };
        }

        case ActionType.SET_MESSAGE_TEMPLATES: {
            return {
                ...state,
                messageTemplates: action.payload,
            };
        }

        case ActionType.SET_NEW_MESSAGE_LOADING: {
            return {
                ...state,
                newMessageIsLoading: action.payload,
            };
        }

        case ActionType.SET_NEW_MESSAGE_ERROR: {
            return {
                ...state,
                newMessageError: action.payload,
            };
        }
        default:
            return state;
    }
};

export default requestReducer;
