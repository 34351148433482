declare global {
    interface Document {
        documentMode?: unknown;
    }

    interface Window {
        MSInputMethodContext?: unknown;
    }
}
const isIE11 = (): boolean =>
    !!window.MSInputMethodContext && !!document.documentMode;

export default isIE11;
