import axios from 'axios';
import msalInstance from './azureAD/utils/msalInstance';
import { azureScopes } from './azureAD/utils/azureScopes';
import getAccessToken from './azureAD/getAccessToken';
import { SessionStorage } from '../utils/enums/SessionStorage';

const carCareApiHttpClient = axios.create();

carCareApiHttpClient.interceptors.request.use(async (config) => {
    try {
        await getAccessToken();
    } catch (exception) {
        console.log({ exception });
        await msalInstance.acquireTokenRedirect({
            account: undefined,
            scopes: azureScopes,
        });
    }
    const accessToken = sessionStorage.getItem(SessionStorage.ACCESS_TOKEN);
    const idToken = sessionStorage.getItem(SessionStorage.ID_TOKEN);
    config.headers['x-access-token'] = `Bearer ${accessToken}`;
    config.headers['Authorization'] = `Bearer ${idToken}`;
    return config;
});
export default carCareApiHttpClient;
