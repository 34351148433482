import React, { useEffect } from 'react';
import { Input, Select } from '@carcare/components';
import Dealer from '../../../../types/Dealer';
import { useTranslation } from 'react-i18next';

interface DamageStatisticsFilterProps {
    readonly damageCenters: Dealer[];
    readonly damageCenter: string;
    readonly setDamageCenter: (value: string) => void;
    readonly fromDate: string;
    readonly toDate: string;
    readonly setFromDate: (value: string) => void;
    readonly setToDate: (value: string) => void;
}

const DamageStatisticsFilter = ({
    damageCenters,
    damageCenter,
    setDamageCenter,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
}: DamageStatisticsFilterProps) => {
    const [translate] = useTranslation();

    useEffect(() => {
        if (damageCenters.length > 0) {
            setDamageCenter(damageCenters[0].dealerNumber);
        }
    }, [damageCenters, setDamageCenter]);

    const allWorkshops = [
        {
            label: translate('all'),
            value: 'all',
            key: 'all',
        },
    ];

    const dealerArray = damageCenters.map((dealer) => ({
        label: `${dealer?.dealerNumber} - ${dealer?.dealerName}`,
        value: dealer.dealerNumber,
    }));

    const array = [...allWorkshops, ...dealerArray];

    return (
        <div className="ba-damage-statistics__filter">
            <Input
                onChange={(e) => {
                    setFromDate(e.target.value);
                }}
                label={{ textContent: `${translate('fromDate')} :` }}
                helpText={{ textKey: 'statisticsDateHelpText' }}
                value={fromDate}
                typeInput="date"
            />
            <Input
                onChange={(e) => {
                    setToDate(e.target.value);
                }}
                label={{ textContent: `${translate('toDate')} :` }}
                helpText={{ textKey: 'statisticsDateHelpText' }}
                value={toDate}
                typeInput="date"
            />
            <Select
                className="ba-damage-statistics__select-damage-center"
                label={{ textKey: 'chooseDamageCenter' }}
                options={array}
                value={damageCenter}
                onChange={(e) => {
                    setDamageCenter(e.target.value);
                }}
            />
        </div>
    );
};

export default DamageStatisticsFilter;
