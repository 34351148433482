import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import '@carcare/components/dist/style.css';
import App from './router/App';
import './stylesheets/_index.scss';
import reducers from './store/reducers';
import EventType from './utils/enums/EventType';
import { LoginEventData } from './utils/analytics/events/eventData';
import { Provider } from 'react-redux';
import { legacy_createStore, StoreEnhancer } from 'redux';
import { MsalProvider } from '@azure/msal-react';

import msalInstance from './http/azureAD/utils/msalInstance';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

interface EventTypeAndData extends LoginEventData {
    event: EventType;
}

declare global {
    interface Window {
        digitalData?: EventTypeAndData[];
        __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer;
    }
}

render(
    <Provider
        store={legacy_createStore(
            reducers,
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
                window.__REDUX_DEVTOOLS_EXTENSION__()
        )}
    >
        <MsalProvider instance={msalInstance}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </MsalProvider>
    </Provider>,
    document.getElementById('root')
);
