import { DamageStatisticsTypes } from './types';
import ActionType from './ActionType';
import Dealer from '../../../types/Dealer';

export interface DamageStatisticsState {
    damageCenters: Dealer[];
    isDamageCentersLoading: boolean;
    hasDamageCentersError: boolean;
}

export const initialState: DamageStatisticsState = {
    damageCenters: [],
    isDamageCentersLoading: false,
    hasDamageCentersError: false,
};

const damageStatisticsReducer = (
    state: DamageStatisticsState = initialState,
    action: DamageStatisticsTypes
): DamageStatisticsState => {
    switch (action.type) {
        case ActionType.SET_DEALERS: {
            return {
                ...state,
                damageCenters: action.payload,
            };
        }
        case ActionType.SET_DAMAGE_CENTERS_LOADING: {
            return {
                ...state,
                isDamageCentersLoading: action.payload,
            };
        }

        case ActionType.SET_DAMAGE_CENTERS_ERROR: {
            return {
                ...state,
                hasDamageCentersError: action.payload,
            };
        }
        case ActionType.RESET_DAMAGE_DASHBOARD: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default damageStatisticsReducer;
