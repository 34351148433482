enum ActionType {
    //newRequest
    SET_NEW_DAMAGE_VALUATIONS = 'SET_NEW_DAMAGE_VALUATIONS',
    SET_NEW_DAMAGE_VALUATIONS_LOADING = 'SET_NEW_DAMAGE_VALUATIONS_LOADING',
    SET_NEW_DAMAGE_VALUATIONS_ERROR = 'SET_NEW_DAMAGE_VALUATIONS_ERROR',
    SET_NEW_DAMAGE_VALUATIONS_FINISHED = 'SET_NEW_DAMAGE_VALUATIONS_FINISHED',

    RESET_DAMAGE_DASHBOARD = 'RESET_DAMAGE_DASHBOARD',
}

export default ActionType;
