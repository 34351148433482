import { getEnvVariable } from '../../env';
import { isLocalhost } from '../../http/utils/isLocalhost';

const initializeAdobeLaunch = () => {
    document.cookie = 'sat_track=true';
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = isLocalhost ? '' : getEnvVariable('ADOBE_ANALYTICS_API');
    scriptTag.async = true;
    document.head.insertBefore(scriptTag, document.head.firstElementChild);
};

export default initializeAdobeLaunch;
