import { NOTIFICATION_SEND } from '../endpoints';
import post from '../post';

const MAX_CHARACTERS_IN_MESSAGE_BODY = 200;

export function postUserNotificationForMessage(
    entryUserId: string,
    threadId: string,
    message: string
) {
    let notificationBody = message.slice(0, MAX_CHARACTERS_IN_MESSAGE_BODY);
    if (message.length > MAX_CHARACTERS_IN_MESSAGE_BODY) {
        notificationBody =
            message.slice(0, MAX_CHARACTERS_IN_MESSAGE_BODY - 3) + '...';
    }

    post(NOTIFICATION_SEND, {
        entryUserId,
        title: 'Ny melding fra verkstedet',
        message: notificationBody,
        navigateTo: `/messages/messages-contact/thread/${threadId}`,
        pushTime: null,
    });
}
