import { MessagesTypes } from './types';
import ActionType from './ActionType';
import { Thread } from 'src/http/message-center/types/generated/MessageCenterTypes';

export interface MessagesState {
    threads: Thread[];
    threadsIsLoading: boolean;
    threadsHasError: boolean;
    threadsHasFinished: boolean;
}

export const initialState: MessagesState = {
    threads: [],
    threadsIsLoading: false,
    threadsHasError: false,
    threadsHasFinished: false,
};

const messagesReducer = (
    state: MessagesState = initialState,
    action: MessagesTypes
): MessagesState => {
    switch (action.type) {
        case ActionType.SET_FETCH_ALL_THREADS: {
            return {
                ...state,
                threads:
                    state.threads.length === 0
                        ? action.payload
                        : state.threads.concat(action.payload),
            };
        }

        case ActionType.SET_FETCH_ALL_THREADS_LOADING: {
            return {
                ...state,
                threadsIsLoading: action.payload,
            };
        }

        case ActionType.SET_FETCH_ALL_THREADS_ERROR: {
            return {
                ...state,
                threadsHasError: action.payload,
            };
        }

        case ActionType.SET_FETCH_ALL_THREADS_FINISHED: {
            return {
                ...state,
                threadsHasFinished: action.payload,
            };
        }
        default:
            return state;
    }
};

export default messagesReducer;
