import React from 'react';
import cn from 'classnames';
import { Icon, Text } from '@carcare/components';
import { warning_outline } from '@carcare/components/dist/icons/notification';
import {
    bilholdClasses,
    ComponentState,
    ComponentType,
} from '@carcare/components/dist/styles';

const IEHeader = () => {
    return (
        <div
            className={cn(
                'ba-ie-header',
                bilholdClasses(ComponentType.CONTAINER, ComponentState.INFO)
            )}
        >
            <Icon file={warning_outline} state={ComponentState.INFO} />
            <Text textKey="ieHeaderWarning" state={ComponentState.INFO} />
        </div>
    );
};

export default IEHeader;
