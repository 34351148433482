import CompletedReason from '../../../utils/enums/CompletedReason';

const completedReasons = [
    {
        reason: CompletedReason.CUSTOMER_ACCEPTED,
        labelKey: 'customerAcceptedLabel',
    },
    {
        reason: CompletedReason.APPRAISE_AT_DAMAGE_CENTER,
        labelKey: 'appraiseAtDamageCenterLabel',
    },
    {
        reason: CompletedReason.CUSTOMER_DECLINED,
        labelKey: 'customerDeclinedLabel',
    },
    {
        reason: CompletedReason.CUSTOMER_NO_RESPONSE,
        labelKey: 'customerNoResponseLabel',
    },
    {
        reason: CompletedReason.CONDEMNED,
        labelKey: 'condemnedLabel',
    },
    {
        reason: CompletedReason.CASH_SETTLEMENT,
        labelKey: 'cashSettlementLabel',
    },
];

export default completedReasons;
