import { OngoingRequestsTypes } from './types';
import ActionType from './ActionType';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

export interface OngoingRequestsState {
    ongoingDamageValuations: DamageAppraiserValuation[];
    ongoingDamageValuationsIsLoading: boolean;
    ongoingDamageValuationsHasError: boolean;
    ongoingDamageValuationsHasFinished: boolean;
}

export const initialState: OngoingRequestsState = {
    ongoingDamageValuations: [],
    ongoingDamageValuationsIsLoading: false,
    ongoingDamageValuationsHasError: false,
    ongoingDamageValuationsHasFinished: false,
};

const ongoingRequestsReducer = (
    state: OngoingRequestsState = initialState,
    action: OngoingRequestsTypes
): OngoingRequestsState => {
    switch (action.type) {
        case ActionType.SET_ONGOING_DAMAGE_VALUATIONS: {
            return {
                ...state,
                ongoingDamageValuations:
                    state.ongoingDamageValuations.length === 0
                        ? action.payload
                        : state.ongoingDamageValuations.concat(action.payload),
            };
        }

        case ActionType.SET_ONGOING_DAMAGE_VALUATIONS_LOADING: {
            return {
                ...state,
                ongoingDamageValuationsIsLoading: action.payload,
            };
        }

        case ActionType.SET_ONGOING_DAMAGE_VALUATIONS_ERROR: {
            return {
                ...state,
                ongoingDamageValuationsHasError: action.payload,
            };
        }

        case ActionType.SET_ONGOING_DAMAGE_VALUATIONS_FINISHED: {
            return {
                ...state,
                ongoingDamageValuationsHasFinished: action.payload,
            };
        }
        default:
            return state;
    }
};

export default ongoingRequestsReducer;
