import {
    isLastMessageFromCustomer,
    sentByCustomerAnswerWithin,
    sentByDealerTimestamp,
} from '../logUtils';
import {
    daysSince,
    hoursSince,
    isExpired,
    remainingHours,
} from '../../../../utils/time/timeUtils';
import DamageAppraiserValuation from '../../../../types/DamageAppraiserValuation';
import {
    initialStatusRequest,
    RequestStatusText,
} from '../../types/RequestStatusText';

const valuationSentText = (
    appraiser: DamageAppraiserValuation
): RequestStatusText => {
    if (isLastMessageFromCustomer(appraiser)) {
        if (isExpired(sentByCustomerAnswerWithin(appraiser))) {
            return {
                ...initialStatusRequest,
                textKey: 'valuationSentExpired',
                isBold: true,
            };
        } else {
            return {
                ...initialStatusRequest,
                textKey: 'valuationSentAnswerWithin',
                hours: remainingHours(sentByCustomerAnswerWithin(appraiser)),
            };
        }
    } else {
        return {
            ...initialStatusRequest,
            textKey: 'valuationSentNotAnsweredIn',
            hours: hoursSince(sentByDealerTimestamp(appraiser)),
            days: daysSince(sentByDealerTimestamp(appraiser)),
        };
    }
};

export default valuationSentText;
