import { CompletedRequestsTypes } from './types';
import ActionType from './ActionType';
import DamageAppraiserValuation from '../../../types/DamageAppraiserValuation';

export interface CompletedRequestsState {
    completedDamageValuations: DamageAppraiserValuation[];
    completedDamageValuationsIsLoading: boolean;
    completedDamageValuationsHasError: boolean;
    completedDamageValuationsHasFinished: boolean;
}

export const initialState: CompletedRequestsState = {
    completedDamageValuations: [],
    completedDamageValuationsIsLoading: false,
    completedDamageValuationsHasError: false,
    completedDamageValuationsHasFinished: false,
};

const completedRequestsReducer = (
    state: CompletedRequestsState = initialState,
    action: CompletedRequestsTypes
): CompletedRequestsState => {
    switch (action.type) {
        case ActionType.SET_COMPLETED_DAMAGE_VALUATIONS: {
            return {
                ...state,
                completedDamageValuations:
                    state.completedDamageValuations.length === 0
                        ? action.payload
                        : state.completedDamageValuations.concat(
                              action.payload
                          ),
            };
        }

        case ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_LOADING: {
            return {
                ...state,
                completedDamageValuationsIsLoading: action.payload,
            };
        }

        case ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_ERROR: {
            return {
                ...state,
                completedDamageValuationsHasError: action.payload,
            };
        }

        case ActionType.SET_COMPLETED_DAMAGE_VALUATIONS_FINISHED: {
            return {
                ...state,
                completedDamageValuationsHasFinished: action.payload,
            };
        }
        default:
            return state;
    }
};

export default completedRequestsReducer;
