import { SessionStorage } from '../../utils/enums/SessionStorage';
import msalInstance from './utils/msalInstance';
import { azureScopes } from './utils/azureScopes';
import { isExpired } from './utils/accesTokenUtils';

interface TokenData {
    readonly accessToken: string;
    readonly idToken: string;
}

const getAccessToken = async (): Promise<TokenData | undefined> => {
    const config = msalInstance;
    const idToken = sessionStorage.getItem(SessionStorage.ID_TOKEN);
    if (!idToken || isExpired(idToken)) {
        try {
            const accounts = config.getAllAccounts();
            const silentResult = await config.acquireTokenSilent({
                scopes: azureScopes,
                account: accounts[0],
                forceRefresh: true,
            });

            sessionStorage.setItem(
                SessionStorage.ID_TOKEN,
                silentResult.idToken
            );
            sessionStorage.setItem(
                SessionStorage.ACCESS_TOKEN,
                silentResult.accessToken
            );

            return {
                accessToken: silentResult.accessToken,
                idToken: silentResult.idToken,
            };
        } catch (_err) {
            await config.acquireTokenRedirect({
                account: undefined,
                scopes: azureScopes,
            });
            return;
        }
    }
};

export default getAccessToken;
