import React from 'react';
import { Page } from '@carcare/components';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';
import {
    Message,
    Thread,
} from '../../http/message-center/types/generated/MessageCenterTypes';
import PageLink from '../../components/page-link/PageLink';
import RequestInfo from './components/request-info/RequestInfo';
import { History } from 'history';
import NewMessage from './components/new-message/NewMessage';
import MessageTemplate from '../../types/MessageTemplate';
import Messages from './components/messages/Messages';
import RequestError from './components/request-error/RequestError';
import { PATH_DAMAGE } from '../../router/routes';
import { lastRouteIsAdminApplication } from 'src/utils/browser/historyBackRoute';

interface RequestProps {
    readonly damageValuation?: DamageAppraiserValuation;
    readonly thread?: Thread;
    readonly messages: Message[];
    readonly history: History;
    readonly hasError: boolean;
    readonly messagesHasError: boolean;
    readonly threadHasError: boolean;
    readonly newMessageError: string;
    readonly messageTemplates: MessageTemplate[];
    readonly loggedInUser: { name: string; username: string };
    readonly hash: string;
}

const Request = ({
    damageValuation,
    thread,
    messages,
    history,
    hasError,
    messageTemplates,
    loggedInUser,
    newMessageError,
    messagesHasError,
    threadHasError,
    hash,
}: RequestProps) => {
    const showMessageComponent =
        !threadHasError && !messagesHasError && damageValuation && thread;
    return (
        <Page className="ba-request">
            <PageLink
                className="ba-list-page__back"
                {...(lastRouteIsAdminApplication
                    ? { onClick: () => history.goBack() }
                    : { path: PATH_DAMAGE })}
                textKey="back"
                isBack
            />
            {!hasError && damageValuation ? (
                <>
                    <RequestInfo
                        hash={hash}
                        damageValuation={damageValuation}
                    />
                    {showMessageComponent ? (
                        <>
                            <Messages thread={thread} messages={messages} />
                            <NewMessage
                                thread={thread}
                                damageValuation={damageValuation}
                                templates={messageTemplates}
                                loggedInUser={loggedInUser}
                                newMessageError={newMessageError}
                            />
                        </>
                    ) : (
                        <RequestError errorMessage="requestPageMessagesError" />
                    )}
                </>
            ) : (
                <RequestError errorMessage="requestPageError" />
            )}
        </Page>
    );
};

export default Request;
