enum DamageValuationStatus {
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
    REQUESTED_CHANGES = 'REQUESTED_CHANGES',
    RECEIVED_CHANGES = 'RECEIVED_CHANGES',
    VALUATION_SENT = 'VALUATION_SENT',
    COMPLETED = 'COMPLETED',
    UNKNOWN = 'UNKNOWN',
}

export default DamageValuationStatus;
