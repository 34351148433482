import React from 'react';
import cn from 'classnames';
import MenuItem from './MenuItem';
import {
    PATH_DAMAGE,
    PATH_HOME,
    PATH_DAMAGE_STATISTICS,
    PATH_MESSAGES,
} from '../../router/routes';
import { Icon, Clickable } from '@carcare/components';
import { clear } from '@carcare/components/dist/icons';
import {
    ComponentMode,
    ComponentState,
    ComponentType,
    bilholdClasses,
} from '@carcare/components/dist/styles';
import { setToggleMenu } from '../../store/view/actions';
import { useDispatch } from '../../store/useStore';
import logoutUser from '../../http/azureAD/logoutUser';
import { useMsalTokenClaims } from '../../http/azureAD/useMsalTokenClaims';
import { GROUPS_WITH_CHAT } from 'src/views/messages/messageIndex';

interface MenuProps {
    readonly isMenuVisible: boolean;
    readonly isAuthenticated?: boolean;
}

function shouldSeeChatMenu(groups: string[]) {
    return groups.some((g) => GROUPS_WITH_CHAT.includes(g));
}

const Menu = ({ isMenuVisible, isAuthenticated }: MenuProps) => {
    const dispatch = useDispatch();
    const groups = useMsalTokenClaims()?.groups ?? [];
    const showChat = shouldSeeChatMenu(groups);

    return (
        <>
            <div
                className={cn(
                    'ba-menu',
                    bilholdClasses(
                        ComponentType.CONTAINER,
                        ComponentState.PRIMARY,
                        {
                            mode: ComponentMode.DARK,
                        }
                    ),
                    {
                        'ba-menu__hidden': !isMenuVisible,
                    }
                )}
            >
                <div className="ba-menu__header-icon">
                    <Clickable
                        onClick={() => dispatch(setToggleMenu(!isMenuVisible))}
                    >
                        <Icon state={ComponentState.NONE} file={clear} />
                    </Clickable>
                </div>
                {isAuthenticated && (
                    <>
                        <MenuItem
                            title={PATH_DAMAGE}
                            link={PATH_DAMAGE}
                            onClick={() => dispatch(setToggleMenu(false))}
                            active={window.location.pathname === PATH_DAMAGE}
                        />

                        <MenuItem
                            title={PATH_DAMAGE_STATISTICS}
                            link={PATH_DAMAGE_STATISTICS}
                            onClick={() => dispatch(setToggleMenu(false))}
                            active={
                                window.location.pathname ===
                                PATH_DAMAGE_STATISTICS
                            }
                        />
                        {showChat && (
                            <MenuItem
                                title={PATH_MESSAGES}
                                link={PATH_MESSAGES}
                                onClick={() => dispatch(setToggleMenu(false))}
                                active={
                                    window.location.pathname === PATH_MESSAGES
                                }
                            />
                        )}
                    </>
                )}
                <div className="ba-menu__lower-content">
                    {isAuthenticated && (
                        <MenuItem
                            title="logout"
                            link={PATH_HOME}
                            onClick={() => {
                                dispatch(setToggleMenu(false));
                                logoutUser();
                            }}
                        />
                    )}
                </div>
            </div>
            {isMenuVisible && <div className="ba-menu__toggle" />}
        </>
    );
};

export default Menu;
