import { AxiosRequestConfig, AxiosResponse } from 'axios';
import isAbsoluteUrl from './utils/isAbsoluteUrl';
import initialHttpState, { HttpState } from './utils/HttpState';
import handleResponse from './handleResponse';
import carCareApiHttpClient from './carCareApiHttpClient';

/* eslint-disable */
const post = <T, R = void>(
    path: string,
    data?: T,
    customHeaders?: Record<string, unknown>,
    options: AxiosRequestConfig = {},
    setHttpState = (nextState: HttpState) => undefined
): Promise<AxiosResponse<R>> => {
    setHttpState({ ...initialHttpState, isLoading: true });
    const serverUrl = '/api';

    const axiosConfig = {
        ...options,
    };

    const absoluteUrl = isAbsoluteUrl(path) ? path : `${serverUrl}/${path}`;
    return handleResponse(
        carCareApiHttpClient.post<R>(absoluteUrl, data, axiosConfig),
        path,
        setHttpState
    );
};
/* eslint-enable */

export default post;
