import React from 'react';
import cn from 'classnames';
import { Icon, IconTextLink, Text } from '@carcare/components';
import { IconFile } from '@carcare/components/dist/types';

interface MenuItemItemProps {
    readonly title: string;
    readonly icon?: IconFile;
    readonly link: string;
    readonly onClick: () => void;
    readonly active?: boolean;
}

const MenuItem = ({
    title,
    icon,
    link,
    onClick,
    active,
}: MenuItemItemProps) => {
    return (
        <IconTextLink
            key={title}
            text={<Text textKey={title} />}
            icon={
                icon ? (
                    <Icon file={icon} className="b-menu__menu-icons" />
                ) : undefined
            }
            className={cn(
                { 'ba-menu__item-active': active },
                'ba-menu__menu-item__content'
            )}
            link={link}
            onClick={() => {
                onClick();
            }}
        />
    );
};

export default MenuItem;
