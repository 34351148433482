import { Page, Spinner, SimpleInfoBox } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import { PATH_DAMAGE } from '../../router/routes';

import { Thread } from 'src/http/message-center/types/generated/MessageCenterTypes';
import PageLink from 'src/components/page-link/PageLink';
import { Threadslist } from './threadsList';
import { useTranslation } from 'react-i18next';

interface ListProps {
    readonly threads: Thread[];
    readonly isLoading: boolean;
    readonly hasFinished: boolean;
    readonly hasError: boolean;
}

const ThreadsListPage = ({
    threads,
    isLoading,
    hasFinished,
    hasError,
}: ListProps) => {
    const [translate] = useTranslation();
    return (
        <Page className="ba-list-page">
            <PageLink
                className="ba-list-page__back"
                path={PATH_DAMAGE}
                textKey="backToDashboard"
                isBack
            />
            <Threadslist threads={threads} />
            {isLoading && (
                <div className="ba-list-page__spinner">
                    <Spinner delayMillis={0} />
                </div>
            )}
            {hasError && (
                <SimpleInfoBox
                    withBorderRadius
                    className="ba-list-page__error"
                    state={ComponentState.ERROR}
                    text={{ textKey: 'somethingWentWrong' }}
                />
            )}
            {hasFinished && threads.length < 1 && (
                <div>{translate('messageNoMessages')}</div>
            )}
        </Page>
    );
};

export default ThreadsListPage;
