import React from 'react';
import cn from 'classnames';
import { Text } from '@carcare/components';
import { ComponentState } from '@carcare/components/dist/styles';
import { useTranslation } from 'react-i18next';

interface ListItemProps {
    readonly listItem: {
        textKey?: string;
        textContent?: string;
        isBold?: boolean;
        hours?: string;
        days?: string;
    }[];
    readonly withBackground: boolean;
    readonly onClick?: () => void;
}

const ListItem = ({ listItem, withBackground, onClick }: ListItemProps) => {
    const [translate] = useTranslation();
    const componentState = withBackground
        ? ComponentState.INFO
        : ComponentState.NONE;

    const listItems = listItem?.map((item, index) => {
        const hoursOrDays = item.hours
            ? `${item.hours}${translate('hoursShort')}`
            : `${item.days} ${translate('days')}`;

        return (
            <td key={index}>
                <Text
                    className={cn({
                        'ba-list-item__column--bold': item.isBold,
                    })}
                    state={componentState}
                    textKey={item.textKey}
                    textContent={item.textContent}
                />
                {(item.hours || item.days) && (
                    <Text
                        className={cn('ba-list-item__column__hours', {
                            'ba-list-item__column--bold': item.isBold,
                        })}
                        textContent={hoursOrDays}
                        state={componentState}
                    />
                )}
            </td>
        );
    });
    return (
        <tbody>
            <tr
                className={cn('ba-list-item', {
                    'ba-list-item--with-background': withBackground,
                })}
                onClick={onClick}
                onKeyDown={(e) => (e.key === 'Enter' ? onClick?.() : undefined)}
                tabIndex={0}
            >
                {listItems}
            </tr>
        </tbody>
    );
};

export default ListItem;
