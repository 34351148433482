import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import initializeAdobeLaunch from '../../utils/analytics/initializeAdobeLaunch';
import assignRole from '../../http/azureAD/utils/assignRole';
import { setUserDetails } from '../../store/account/actions';
import getAccessToken from '../../http/azureAD/getAccessToken';
import handleDisableReactDevTools from '../../utils/browser/handleDisableReactDevTools';
import { setInitiated } from '../../store/view/actions';
import { useDispatch } from '../../store/useStore';
import { useMsal } from '@azure/msal-react';
import { initializeDatadog } from '../../utils/initializeDataDog';

const useInitializeAdmin = () => {
    const isInitiated = useSelector((store) => store.view.isInitiated);
    const dispatch = useDispatch();
    const { accounts } = useMsal();

    useEffect(() => {
        handleDisableReactDevTools();
        initializeAdobeLaunch();
        initializeDatadog();
    }, []);

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            getAccessToken().finally(() => dispatch(setInitiated(true)));
            const user = accounts[0];
            const tokenClaims = user.idTokenClaims as
                | { roles: string[] }
                | undefined;
            const roles = tokenClaims?.roles ?? [];
            if (roles?.length > 0) {
                assignRole(dispatch, roles);
            }
            dispatch(setUserDetails(user.name ?? '', user.username));
        } else {
            dispatch(setInitiated(true));
        }
    }, [accounts, dispatch]);

    return { isInitiated };
};

export default useInitializeAdmin;
