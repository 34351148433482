import React from 'react';
import { Page } from '@carcare/components';
import Damage from './Damage';

const Index = () => (
    <Page>
        <Damage />
    </Page>
);

export default Index;
