import { ViewTypes } from './types';
import ActionType from './ActionType';

export interface ViewState {
    isMenuVisible: boolean;
    isInitiated: boolean;
}

export const initialState: ViewState = {
    isMenuVisible: false,
    isInitiated: false,
};

const viewReducer = (
    state: ViewState = initialState,
    action: ViewTypes
): ViewState => {
    switch (action.type) {
        case ActionType.SET_TOGGLE_MENU: {
            return {
                ...state,
                isMenuVisible: action.payload,
            };
        }

        case ActionType.SET_INITIATED: {
            return {
                ...state,
                isInitiated: action.payload,
            };
        }
        default:
            return state;
    }
};

export default viewReducer;
