const environments: { url: string; name: string }[] = [
    {
        /* 0 = Production  */
        url: 'https://bilholdadmin.moller.no',
        name: 'prod',
    },
    {
        /* 1 = Staging */
        url: 'https://bilholdadmin-stage.moller.no',
        name: 'stage',
    },
    {
        /* 2 = TEST */
        url: 'https://bilholdadmin-test.moller.no',
        name: 'test',
    },
    {
        /* 3 = Development */
        url: 'https://bilholdadmin-dev.moller.no',
        name: 'dev',
    },
    {
        /* 4 = Local dev */
        url: 'http://localhost:3000',
        name: 'local',
    },
];
export default environments;
