import { useFetchAllThreads } from 'src/http/message-center/fetchThreads';
import useStore from 'src/store/useStore';
import ThreadsListPage from './threadsListPage';

export const GROUPS_WITH_CHAT = ['G050', 'G006']; // TODO: This should be updated after access is set by participant workshop id
const Messagesindex = () => {
    const [store] = useStore();

    const {
        messages: {
            threads,
            threadsIsLoading,
            threadsHasError,
            threadsHasFinished,
        },
    } = store;

    useFetchAllThreads();

    return (
        <ThreadsListPage
            hasError={threadsHasError}
            isLoading={threadsIsLoading}
            threads={threads}
            hasFinished={threadsHasFinished}
        />
    );
};

export default Messagesindex;
