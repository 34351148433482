import { useEffect } from 'react';
import DamageAppraiserValuation from 'src/types/DamageAppraiserValuation';

const useFetchDamageValuations = (
    damageAppraiserValuations: DamageAppraiserValuation[],
    isLoading: boolean,
    hasFinished: boolean,
    hasError: boolean,
    fetchDamageValuations: () => void
) => {
    useEffect(() => {
        if (
            !damageAppraiserValuations.length &&
            !isLoading &&
            !hasFinished &&
            !hasError
        ) {
            fetchDamageValuations();
        }
    }, [
        damageAppraiserValuations,
        isLoading,
        hasFinished,
        hasError,
        fetchDamageValuations,
    ]);
};

export default useFetchDamageValuations;
