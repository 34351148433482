import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Spinner } from '@carcare/components';
import { Size } from '@carcare/components/dist/types';
import { ComponentState } from '@carcare/components/dist/styles';
import { vector } from '@carcare/components/dist/icons/info';
import { check } from '@carcare/components/dist/icons';
import Dealer from '../../../../types/Dealer';
import DamageStatisticsData from '../../../../types/DamageStatistics';
import {
    countAcceptedValuations,
    countCompletedReason,
    countStatus,
    countUnanswered,
} from '../../utils/countStatistics';
import StatisticsCard from '../../../../components/statistics-card/StatisticsCard';
import StatisticsList from '../statistics-list/StatisticsList';
import {
    completedReasonItemList,
    statusItemList,
} from '../../utils/statisticsListItems';

interface DamageStatisticsContentProps {
    readonly isLoading: boolean;
    readonly choseAll: boolean;
    readonly dealerStats: DamageStatisticsData;
    readonly selectedDamageCenter?: Dealer;
}

const DamageStatisticsContent = ({
    choseAll,
    selectedDamageCenter,
    dealerStats,
    isLoading,
}: DamageStatisticsContentProps) => {
    const [translate] = useTranslation();
    const chosenDealerTitle = choseAll
        ? translate('all')
        : `${selectedDamageCenter?.dealerName}`;
    const countAll =
        countStatus(dealerStats) + countCompletedReason(dealerStats);

    const countAllText = `${translate(
        'statisticsCount'
    )}: <b> ${countAll} ${translate('quantityShortened')} </b>`;

    const spinner = () => {
        return (
            <div className="ba-damage-statistics__content-spinner">
                <Spinner delayMillis={0} />
            </div>
        );
    };

    return (
        <div className="ba-damage-statistics__content">
            {isLoading ? (
                spinner()
            ) : (
                <>
                    <Text
                        className="ba-damage-statistics__content-title"
                        textContent={chosenDealerTitle}
                    />
                    <Text
                        size={Size.LARGE}
                        parseHtml
                        textContent={`${countAllText}`}
                    />
                    <div className="ba-damage-statistics__content-cards">
                        <StatisticsCard
                            iconText={translate('unansweredValuationsLeads')}
                            cardContentText={`Kr ${countUnanswered(
                                dealerStats
                            )}`}
                            icon={vector}
                            iconState={ComponentState.INFO}
                            state={ComponentState.INFO}
                            rightText={`${
                                dealerStats?.status?.VALUATION_SENT?.count
                            } ${translate('quantityShortened')}`}
                        />
                        <StatisticsCard
                            iconText={translate('acceptedValuations')}
                            cardContentText={`Kr ${countAcceptedValuations(
                                dealerStats
                            )}`}
                            icon={check}
                            iconState={ComponentState.SUCCESS}
                            rightText={`${
                                dealerStats?.completedReason.CUSTOMER_ACCEPTED
                                    ?.count
                            } ${translate('quantityShortened')}`}
                            state={ComponentState.SUCCESS}
                        />
                    </div>
                    <div className="ba-damage-statistics__content-statistics">
                        <StatisticsList
                            topElementShade
                            statusItemList={statusItemList(dealerStats)}
                        />
                        <StatisticsList
                            topElementShade
                            statusItemList={completedReasonItemList(
                                dealerStats
                            )}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default DamageStatisticsContent;
