import React from 'react';
import useStore from '../../store/useStore';
import DamageStatistics from './DamageStatistics';

const Index = () => {
    const [store] = useStore();
    const {
        damage: {
            damageStatistics: {
                damageCenters,
                hasDamageCentersError,
                isDamageCentersLoading,
            },
        },
    } = store;
    return (
        <DamageStatistics
            damageCenters={damageCenters}
            hasDamageCentersError={hasDamageCentersError}
            isDamageCentersLoading={isDamageCentersLoading}
        />
    );
};

export default Index;
