import composeUrl from '../utils/composeUrl';
import { PUT_THREAD_STATUS } from '../endpoints';
import { put } from '../put';

export const putThreadStatusToClosed = async (
    threadId: string,
    onSuccess?: () => void
) => {
    try {
        await put(composeUrl(PUT_THREAD_STATUS, threadId, 'CLOSED'));

        if (onSuccess) {
            onSuccess();
        }
    } catch (e: unknown) {
        console.error(e);
    }
};
