import React from 'react';
import {
    setCompletedDamageValuations,
    setCompletedDamageValuationsError,
    setCompletedDamageValuationsFinished,
    setCompletedDamageValuationsLoading,
} from 'src/store/damage/completedRequests/actions';
import DamageListPage from '../../components/damage-list-page/DamageListPage';
import useStore from '../../store/useStore';
import DamageValuationState from '../../utils/enums/DamageValuationState';

const Index = () => {
    const [store] = useStore();
    const {
        damage: {
            completedRequests: {
                completedDamageValuations,
                completedDamageValuationsIsLoading,
                completedDamageValuationsHasError,
                completedDamageValuationsHasFinished,
            },
        },
    } = store;

    return (
        <DamageListPage
            listTitle="allCompletedRequests"
            damageAppraiserValuations={completedDamageValuations}
            isLoading={completedDamageValuationsIsLoading}
            hasFinished={completedDamageValuationsHasFinished}
            hasError={completedDamageValuationsHasError}
            setIsLoading={setCompletedDamageValuationsLoading}
            setHasError={setCompletedDamageValuationsError}
            setHasFinished={setCompletedDamageValuationsFinished}
            setDamageAppraiserValuations={setCompletedDamageValuations}
            state={DamageValuationState.INACTIVE}
        />
    );
};

export default Index;
