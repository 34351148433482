import { Dispatch } from 'redux';
import { SetUserRole } from 'src/store/account/types';
import { setRole } from '../../../store/account/actions';
import { UserRole } from '../../../utils/enums/UserRole';

const setAuthRole = (roles: string[]): string => {
    const checkForRole = (role: string): string => {
        return roles?.filter((p) => p === role)?.toString();
    };

    if (checkForRole(UserRole.ROLE_ADMINISTRATOR)) {
        return UserRole.ROLE_ADMINISTRATOR;
    } else if (checkForRole(UserRole.ROLE_APPRAISER)) {
        return UserRole.ROLE_APPRAISER;
    } else return '';
};

const assignRole = (dispatch: Dispatch, roles: string[]): SetUserRole => {
    return dispatch(setRole(setAuthRole(roles)));
};

export default assignRole;
