import React from 'react';
import { Text } from '@carcare/components';

interface ListHeaderProps {
    readonly listHeader: string[];
}

const ListHeader = ({ listHeader }: ListHeaderProps) => {
    return (
        <thead>
            <tr className="ba-list-header">
                {listHeader?.map((header) => (
                    <th key={header}>
                        <Text textKey={header} />
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default ListHeader;
