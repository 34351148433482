import React, { useState } from 'react';
import { LoadingOverlay, Text, Icon, Modal, Button } from '@carcare/components';
import { add_circle } from '@carcare/components/dist/icons';
import { ComponentState } from '@carcare/components/dist/styles';
import handleFileUpload from './utils/handleFileUpload';

interface UploadButtonProps {
    readonly relatedId: number;
    readonly uploadMedia: (media: {
        file: string;
        name: string;
    }) => Promise<void>;
    readonly textKey: string;
}

const UploadButton = ({
    uploadMedia,
    relatedId,
    textKey,
}: UploadButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [invalidUpload, setInvalidUpload] = useState(false);

    return (
        <div className="ba-upload-button">
            {isLoading && <LoadingOverlay delayMillis={200} />}
            {invalidUpload && (
                <Modal
                    close={() => setInvalidUpload(false)}
                    className="ba-invalid-upload"
                    closeOnOverlayClick
                >
                    <Text textKey="invalidUpload" />
                    <Button
                        textKey="ok"
                        onClick={() => setInvalidUpload(false)}
                    />
                </Modal>
            )}
            <label
                htmlFor={relatedId.toString()}
                className="ba-upload-button__label"
            >
                {!isLoading && (
                    <input
                        className="ba-upload-button__input"
                        id={relatedId.toString()}
                        onFocus={() =>
                            document
                                .getElementById(relatedId.toString())
                                ?.blur()
                        }
                        type={'file'}
                        onChange={(e) =>
                            handleFileUpload(
                                e,
                                uploadMedia,
                                setIsLoading,
                                setInvalidUpload
                            )
                        }
                    />
                )}
                <Icon file={add_circle} state={ComponentState.INFO} />
                <Text textKey={textKey} state={ComponentState.INFO} />
            </label>
        </div>
    );
};

export default UploadButton;
