import { combineReducers, StateFromReducersMapObject } from 'redux';
import damageReducer from './damage/damageReducer';
import viewReducer from './view/viewReducer';
import accountReducer from './account/accountReducer';
import messagesReducer from './messages/messagesReducer';

declare module 'react-redux' {
    interface DefaultRootState extends ApplicationState {}
}

export type ApplicationState = StateFromReducersMapObject<typeof reducers>;

const reducers = {
    damage: damageReducer,
    view: viewReducer,
    account: accountReducer,
    messages: messagesReducer,
};

export default combineReducers(reducers);
