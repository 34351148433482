import download from 'downloadjs';
import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_VALUATION_IMAGES_ZIP } from '../endpoints';

const fetchValuationImages = (id: number, folderName: string) =>
    get(composeUrl(GET_VALUATION_IMAGES_ZIP, id), {
        responseType: 'blob',
    }).request.then((res) => {
        download(res.data, `${folderName}.zip`, 'application/zip');
    });

export default fetchValuationImages;
