import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Clickable, Icon, Text } from '@carcare/components';
import { menu_inverted } from '@carcare/components/dist/icons';
import {
    bilholdClasses,
    ComponentMode,
    ComponentState,
    ComponentType,
} from '@carcare/components/dist/styles';
import currentPageName from '../../router/utils/currentPageName';
import { LANGUAGE_CODE } from '../../i18n/constants';
import Norwegian from '../../assets/flags/no-flag.svg';
import English from '../../assets/flags/en-flag.svg';
import { useTranslation } from 'react-i18next';
import isIE11 from '../../utils/browser/isIE11';
import redirectToPage from '../../router/utils/redirectToPage';

interface HeaderProps {
    readonly toggleMenu: () => void;
    readonly name: string;
    readonly isAuthenticated: boolean;
    readonly currentPage: string;
}

const Header = ({
    toggleMenu,
    name,
    isAuthenticated,
    currentPage,
}: HeaderProps) => {
    const [translate, i18n] = useTranslation();

    return (
        <div
            id="ba-header"
            className={cn(
                'ba-header-padding',
                bilholdClasses(
                    ComponentType.CONTAINER,
                    ComponentState.PRIMARY,
                    {
                        mode: ComponentMode.DARK,
                    }
                ),
                { 'ba-header--ie': isIE11() }
            )}
        >
            <div className="ba-header">
                <div className="ba-header__content">
                    <div className="ba-header__content-first-group">
                        {isAuthenticated && (
                            <Clickable onClick={() => toggleMenu()}>
                                <Icon
                                    file={menu_inverted}
                                    className="ba-header-menu-icon"
                                />
                            </Clickable>
                        )}
                        <Link
                            to={redirectToPage(currentPage)}
                            className="ba-header__text"
                        >
                            <Text
                                textContent={translate(
                                    currentPageName(currentPage)
                                )}
                            />
                        </Link>
                    </div>
                    <div className="ba-header__content-second-group">
                        <Text textContent={name} />
                        <div className="ba-header__content-second-group__translations">
                            <Clickable
                                onClick={() =>
                                    i18n.changeLanguage(LANGUAGE_CODE.NORWEGIAN)
                                }
                            >
                                <img src={Norwegian} alt="Norwegian" />
                            </Clickable>
                            <Clickable
                                onClick={() =>
                                    i18n.changeLanguage(LANGUAGE_CODE.ENGLISH)
                                }
                            >
                                <img src={English} alt="English" />
                            </Clickable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
