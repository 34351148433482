import composeUrl from '../utils/composeUrl';
import { UPDATE_DAMAGE_VALUATION } from '../endpoints';
import post from '../post';
import CompletedReason from '../../utils/enums/CompletedReason';
import DamageValuationStatus from '../../utils/enums/DamageValuationStatus';

const updateDamageValuation = (
    id: number,
    data: { completedReason?: CompletedReason; status: DamageValuationStatus }
) => post(composeUrl(UPDATE_DAMAGE_VALUATION, id), data);

export default updateDamageValuation;
