import { PATH_DAMAGE } from '../../router/routes';
import { History } from 'history';

export const lastRouteIsAdminApplication = document.referrer?.includes(
    window.location.origin + '/damage'
);

const historyBackRoute = (history: History) => {
    return lastRouteIsAdminApplication
        ? history.goBack()
        : window.location.replace(PATH_DAMAGE);
};

export default historyBackRoute;
