import { Button, ButtonType } from '@carcare/components';
import { useState } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { postSetValuationSurveyor } from 'src/http/carcareApi/postValuationSurveyor';
import useStore from 'src/store/useStore';
import DamageAppraiserValuation from 'src/types/DamageAppraiserValuation';
import DamageValuationStatus from 'src/utils/enums/DamageValuationStatus';
import CompleteModal from '../complete-modal/CompleteModal';

export function ManageRequest({
    damageValuation,
}: {
    damageValuation: DamageAppraiserValuation;
}) {
    const [modalIsShowing, setModalShowing] = useState(false);
    const [store, dispatch] = useStore();
    const {
        account: { user },
    } = store;

    return (
        <div className="ba-request-info-header__button">
            <ClaimRequest
                {...{ damageValuation, dispatch }}
                userName={user.name}
            />
            <CompleteRequest {...{ setModalShowing, damageValuation }} />
            <CompleteModal
                setModalShowing={setModalShowing}
                id={damageValuation?.id}
                show={modalIsShowing}
            />
        </div>
    );
}

function ClaimRequest({
    damageValuation,
    dispatch,
    userName,
}: {
    damageValuation: DamageAppraiserValuation;
    dispatch: Dispatch<AnyAction>;
    userName: string;
}) {
    if (damageValuation?.status === DamageValuationStatus.COMPLETED) {
        return null;
    }
    return (
        <Button
            textKey="selfAssignRequest"
            buttonType={ButtonType.PRIMARY}
            onClick={() => {
                return doPostValuationSurveyor(
                    damageValuation,
                    dispatch,
                    userName
                );
            }}
        />
    );
}

function CompleteRequest({
    damageValuation,
    setModalShowing,
}: {
    damageValuation: DamageAppraiserValuation;
    setModalShowing: (x: boolean) => void;
}) {
    if (damageValuation?.status === DamageValuationStatus.COMPLETED) {
        return null;
    }
    return (
        <Button
            textKey="completeRequest"
            buttonType={ButtonType.SECONDARY}
            onClick={() => setModalShowing(true)}
        />
    );
}

async function doPostValuationSurveyor(
    damageValuation: DamageAppraiserValuation,
    dispatch: Dispatch<AnyAction>,
    username: string
) {
    postSetValuationSurveyor(
        damageValuation?.id,
        {
            surveyor: username,
        },
        dispatch,
        damageValuation
    );
}
