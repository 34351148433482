import axios, { AxiosRequestConfig } from 'axios';
import isAbsoluteUrl from './utils/isAbsoluteUrl';
import initialHttpState, { HttpState } from './utils/HttpState';
import handleResponse from './handleResponse';
import getCurrentEnvironment from '../utils/env/getCurrentEnvironment';
import carCareApiHttpClient from './carCareApiHttpClient';

const get = (
    path: string,
    config?: AxiosRequestConfig,
    setHttpState: (nextState: HttpState) => void = () => undefined
) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const serverUrl = getCurrentEnvironment();

    const axiosConfig = {
        ...config,
    };

    const absoluteUrl = isAbsoluteUrl(path) ? path : `${serverUrl}/${path}`;

    setHttpState({ ...initialHttpState, isLoading: true });
    return {
        request: handleResponse(
            carCareApiHttpClient.get(absoluteUrl, {
                ...axiosConfig,
                cancelToken: source.token,
            }),
            path,
            setHttpState
        ),
        cancel: () => source.cancel(),
    };
};

export default get;
