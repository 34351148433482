import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

interface OnPageChangeProps {
    children: React.ReactNode;
}

const OnPageChange = ({ children }: OnPageChangeProps) => {
    const location = useLocation();
    useEffect(() => {
        document.body.scrollTop = 0;
    }, [location]);
    return <>{children}</>;
};

export default OnPageChange;
