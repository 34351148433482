import ActionType from './ActionType';
import {
    SetDealers,
    SetDamageCentersLoading,
    SetDamageCentersError,
} from './types';
import Dealer from '../../../types/Dealer';

export const setDealers = (dealers: Dealer[]): SetDealers => ({
    type: ActionType.SET_DEALERS,
    payload: dealers,
});

export const setDamageCentersLoading = (
    value: boolean
): SetDamageCentersLoading => ({
    type: ActionType.SET_DAMAGE_CENTERS_LOADING,
    payload: value,
});

export const setDamageCentersError = (
    value: boolean
): SetDamageCentersError => ({
    type: ActionType.SET_DAMAGE_CENTERS_ERROR,
    payload: value,
});
