import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import get from '../get';
import composeUrl from '../utils/composeUrl';
import { GET_THREAD } from '../endpoints';
import fetchMessages from './fetchMessages';
import {
    setRequestThread,
    setRequestThreadError,
    setRequestThreadLoading,
} from 'src/store/damage/request/actions';

const fetchThreadAndMessages = (
    id: number,
    participantId: string,
    dispatch: Dispatch<AnyAction>
) => {
    dispatch(setRequestThreadError(false));
    dispatch(setRequestThreadLoading(true));
    return get(composeUrl(GET_THREAD, 'DAMAGE_APPRAISER', id), {
        headers: { participantId },
    })
        .request.then((res) => {
            dispatch(setRequestThread(res.data));
            dispatch(setRequestThreadLoading(false));
            fetchMessages(res.data.threadId, participantId, dispatch);
        })
        .catch(() => {
            dispatch(setRequestThreadError(true));
            dispatch(setRequestThreadLoading(false));
        });
};

export default fetchThreadAndMessages;
