import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Thread } from 'src/http/message-center/types/generated/MessageCenterTypes';

export const isSeen = (thread: Thread) => {
    return !!thread.participants?.some((x) => x.internal && x.seen);
};

const statuses = ['all', 'answered', 'unanswered'] as const;
type Status = typeof statuses[number];
function isStatus(status: string | null): status is Status {
    return statuses.includes(status as Status);
}

export function useFilterThreads(threads: Thread[]) {
    const messageFilter = window.sessionStorage.getItem('messageFilter');
    let status: Status = 'all';
    if (isStatus(messageFilter)) status = messageFilter;
    const [filter, setFilter] = useState<Status>(status);

    const setChatStatus = (status: Status) => {
        window.sessionStorage.setItem('messageFilter', status);
        setFilter(status);
    };

    if (filter === 'all') {
        return {
            threads,
            filter,
            setFilter: setChatStatus,
        };
    }

    if (filter === 'answered') {
        return {
            threads: threads.filter((x) => isSeen(x)),
            filter,
            setFilter: setChatStatus,
        };
    }

    return {
        threads: threads.filter((x) => !isSeen(x)),
        filter,
        setFilter: setChatStatus,
    };
}

export function FilterThreads({
    value,
    setValue,
}: {
    value: Status;
    setValue: (x: Status) => void;
}) {
    const [translate] = useTranslation();
    const handleSelect = (
        e: ChangeEvent<
            HTMLSelectElement & {
                value: Status;
            }
        >
    ) => {
        setValue(e.target.value);
    };
    return (
        <div className="ba-damage-list__filter">
            <label htmlFor="statusFilter">
                {translate('messageFilterLabel')}
            </label>
            <select
                className={'ba-damage-list__select'}
                value={value}
                onChange={handleSelect}
            >
                <option value="all">{translate('messageFilterAll')}</option>
                <option value="answered">
                    {translate('messageFilterAnswered')}
                </option>
                <option value="unanswered">
                    {translate('messageFilterUnanswered')}
                </option>
            </select>
        </div>
    );
}
