import getCurrentEnvironment from './env/getCurrentEnvironment';
import { CARCARE_SURVEYOR_API } from '../http/endpoints';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const initializeDatadog = () => {
    const env = getCurrentEnvironment().name;
    if (env === 'local') {
        return;
    }
    datadogLogs.init({
        clientToken: 'pubbff37927b9d85634afbc4bb6afb80e90',
        site: 'datadoghq.eu',
        service: 'bilhold-admin-frontend',
        env,
        version: process.env.REACT_APP_GIT_REV,
        forwardErrorsToLogs: true,
        sampleRate: 100,
    });
    datadogRum.init({
        applicationId: '2497aa3e-702c-4041-acdf-fd83c1882bad',
        clientToken: 'pubbff37927b9d85634afbc4bb6afb80e90',
        site: 'datadoghq.eu',
        service: 'bilhold-admin-frontend',
        env,
        version: process.env.REACT_APP_GIT_REV,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingOrigins: [
            window.location.origin,
            new URL(CARCARE_SURVEYOR_API).origin,
        ],
    });
    datadogRum.startSessionReplayRecording();
};
