import getCurrentEnvironment from '../env/getCurrentEnvironment';
import environments from '../env/environments';

declare global {
    interface Window {
        __REACT_DEVTOOLS_GLOBAL_HOOK__?: Record<string, unknown>;
    }
}

const disableReactDevTools = (): void => {
    const noop = (): void => undefined;
    const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;

    if (typeof DEV_TOOLS === 'object') {
        for (const prop in DEV_TOOLS) {
            if (Object.prototype.hasOwnProperty.call(DEV_TOOLS, prop)) {
                if (prop === 'renderers') {
                    DEV_TOOLS[prop] = new Map();
                    continue;
                }
                DEV_TOOLS[prop] =
                    typeof DEV_TOOLS[prop] === 'function' ? noop : null;
            }
        }
    }
};

const handleDisableReactDevTools = () => {
    const { url } = getCurrentEnvironment();

    switch (url) {
        /* Disable for production */
        case environments[0].url:
        case environments[1].url:
            return disableReactDevTools();
    }
};

export default handleDisableReactDevTools;
