import composeUrl from '../utils/composeUrl';
import { POST_MESSAGE } from '../endpoints';
import post from '../post';
import postValuationSurveyor from '../carcareApi/postValuationSurveyor';
import DamageAppraiserValuation from '../../types/DamageAppraiserValuation';
import { Dispatch } from 'redux';
import { Attachment, Message } from './types/generated/MessageCenterTypes';
import {
    setNewMessageError,
    setNewMessageLoading,
} from 'src/store/damage/request/actions';
import updateDavStatus from '../carcareApi/updateDavStatus';
import DamageValuationStatus from '../../utils/enums/DamageValuationStatus';

const postMessage = async (
    dispatch: Dispatch,
    damageValuation: DamageAppraiserValuation,
    threadId: string | undefined,
    attachments: Attachment[],
    loggedInUser: { name: string; username: string },
    message: string,
    newStatus: DamageValuationStatus,
    resetNewMessage: () => void
) => {
    const body = [
        {
            source: 'BILHOLD_ADMIN',
            type: 'IN_APP',
            sender: {
                senderId: loggedInUser.username,
                name: loggedInUser.name,
                internal: true,
            },
            content: message,
            attachments: attachments.length !== 0 ? attachments : [],
        } as Message,
    ];

    dispatch(setNewMessageLoading(true));
    dispatch(setNewMessageError(''));

    try {
        await post(composeUrl(POST_MESSAGE, threadId ?? ''), body);
        if (!damageValuation?.surveyor) {
            await postValuationSurveyor(
                damageValuation?.id,
                loggedInUser.username,
                {
                    surveyor: loggedInUser.username,
                },
                dispatch,
                damageValuation
            );
        } else {
            dispatch(setNewMessageLoading(false));
        }
        resetNewMessage();
        await updateDavStatus(damageValuation?.damageAppraiser?.id, newStatus);
    } catch {
        dispatch(setNewMessageError('postMessageError'));
        dispatch(setNewMessageLoading(false));
    }
};

export default postMessage;
