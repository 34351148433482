import composeUrl from '../utils/composeUrl';
import { POST_MESSAGE } from '../endpoints';
import post from '../post';
import { Dispatch } from 'redux';
import { Message } from './types/generated/MessageCenterTypes';
import {
    setNewMessageError,
    setNewMessageLoading,
} from 'src/store/damage/request/actions';

const postMessageSimple = async (
    dispatch: Dispatch,
    threadId: string | undefined,
    loggedInUser: { name: string; username: string },
    message: string,
    callBack: () => void
) => {
    const body = [
        {
            source: 'BILHOLD_ADMIN',
            type: 'IN_APP',
            sender: {
                senderId: loggedInUser.username,
                name: loggedInUser.name,
                internal: true,
            },
            content: message,
        } as Message,
    ];

    dispatch(setNewMessageLoading(true));
    dispatch(setNewMessageError(''));

    try {
        await post(composeUrl(POST_MESSAGE, threadId ?? ''), body);
        dispatch(setNewMessageLoading(false));
        callBack();
    } catch {
        dispatch(setNewMessageError('postMessageError'));
        dispatch(setNewMessageLoading(false));
    }
};

export default postMessageSimple;
