import { AccountTypes } from './types';
import ActionType from './ActionType';

export interface AccountState {
    errorMessage: string;
    isAuthenticated: boolean;
    user: { name: string; username: string };
    role: string;
}

export const initialState: AccountState = {
    errorMessage: '',
    isAuthenticated: false,
    user: { name: '', username: '' },
    role: '',
};

const accountReducer = (
    state: AccountState = initialState,
    action: AccountTypes
): AccountState => {
    switch (action.type) {
        case ActionType.SET_LOGIN_ERROR: {
            return {
                ...state,
                errorMessage: action.payload,
            };
        }
        case ActionType.SET_USER_DETAILS: {
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
        }
        case ActionType.SET_USER_ROLE: {
            return {
                ...state,
                role: action.payload,
                isAuthenticated: true,
            };
        }
        case ActionType.SET_INITIAL_STATE: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default accountReducer;
