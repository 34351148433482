import { useMsal } from '@azure/msal-react';
import { TokenClaims } from '@azure/msal-common';

type Claims = TokenClaims & {
    groups: string[];
};

export function useMsalTokenClaims() {
    const { accounts } = useMsal();
    const claims = accounts[0]?.idTokenClaims as Claims | undefined;
    if (!claims) {
        return undefined;
    }
    const groups = claims.groups.map((x) => x.replace('moller.local\\', ''));
    return {
        ...claims,
        groups,
    };
}
